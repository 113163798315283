import { put, call, takeLatest, select } from 'redux-saga/effects';
import { apiCall } from '../../utils/apiCall';
import { USERLIST, USERS } from '../../utils/urls';
import {
    USER_LIST_START,
    USER_LIST_COMPLETE,
    USER_EDIT_START,
    USER_EDIT_COMPLETE,
    CREATE_NEW_USER_START,
    CREATE_NEW_USER_COMPLETE,
    IS_MODAL_CREATE_USER,
} from '../actionType';
import { tokenSelector } from '../selector/';
import { isLoadingAction, snackbarAction, isModalEditUserAction } from '../actions/utilityActions';

function* allUserList({ pageNumber, size }) {
    try {
        const token = yield select(tokenSelector);
        const result = yield call(apiCall, `${USERLIST}/${pageNumber}/${size}`, null, token, 'GET');

        yield put({ type: USER_LIST_COMPLETE, result });
    } catch (error) {
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error?.data?.msg,
            })
        );
    }
    yield put(isLoadingAction(false));
}

function* updateUser({ id, data }) {
    try {
        const token = yield select(tokenSelector);
        const result = yield call(apiCall, `${USERS}/${id}`, data, token, 'PUT', {
            'Access-Control-Allow-Origin': '*',
        });

        yield put({ type: USER_EDIT_COMPLETE, id, data });

        yield put(
            snackbarAction({
                open: true,
                type: 'success',
                message: result?.data?.message,
            })
        );
    } catch (error) {
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error?.data?.msg,
            })
        );
    }

    yield put(isLoadingAction(false));
    yield put(isModalEditUserAction(false));
}

function* createUser({ data }) {
    try {
        const token = yield select(tokenSelector);
        const result = yield call(apiCall, USERS, data, token, 'POST');

        yield put(
            snackbarAction({
                open: true,
                type: 'success',
                message: result?.data?.message,
            })
        );

        yield put({
            type: CREATE_NEW_USER_COMPLETE,
            data: { _id: Math.random() * 9999999, ...data },
        });

        yield put({ type: IS_MODAL_CREATE_USER, isModal: false });
        yield put({ type: USER_LIST_START, pageNumber: 1, size: 10 });
    } catch (error) {
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error?.response?.data?.message || '',
            })
        );
    }
    yield put(isLoadingAction(false));
}

export default function* watchUserList() {
    yield takeLatest(USER_LIST_START, allUserList);
    yield takeLatest(USER_EDIT_START, updateUser);
    yield takeLatest(CREATE_NEW_USER_START, createUser);
}
