import { put, call, takeLatest } from 'redux-saga/effects';
import { LOGIN_START, LOGIN_COMPLETE, LOGOUT_START, LOGOUT_COMPLETE } from '../actionType/';
import { authCall } from '../../utils/apiCall';
import { AUTH } from '../../utils/urls';
import { isLoadingAction, snackbarAction } from '../actions/utilityActions';
import {
    setLocalStorage,
    GET_TOKEN,
    REFRESH_TOKEN,
    ORGANIZATION,
    EMAIL,
    rmLocalStorage,
} from '../../utils/localStorage';

export function* logIn({ payload }) {
    try {
        const { data } = yield call(authCall, AUTH, payload, 'POST');

        switch (data.status_code) {
            case 401:
                yield put(
                    snackbarAction({
                        open: true,
                        type: 'error',
                        message: data.description,
                    })
                );
                break;
            case 200:
                yield put({ type: LOGIN_COMPLETE, payload: data });
                yield setLocalStorage(data.access_token, GET_TOKEN);
                yield setLocalStorage(data.refresh_token, REFRESH_TOKEN);
                yield setLocalStorage(data.organization, ORGANIZATION);
                break;
            default:
                yield put(
                    snackbarAction({
                        open: true,
                        type: 'error',
                        message: 'Got unexpected Error',
                    })
                );
                break;
        }
    } catch (error) {
        const errorResponse = error.response;
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: errorResponse.data.description || 'Internal Message',
            })
        );
    }
    yield put(isLoadingAction(false));
}

function* logOut() {
    yield rmLocalStorage(EMAIL);
    yield rmLocalStorage(GET_TOKEN);
    yield rmLocalStorage(ORGANIZATION);
    yield rmLocalStorage(REFRESH_TOKEN);

    put({ type: LOGOUT_COMPLETE });
}

export default function* authUser() {
    yield takeLatest(LOGIN_START, logIn);
    yield takeLatest(LOGOUT_START, logOut);
}
