import { ThemeProvider } from '@material-ui/styles';
import { theme } from './utils/theme';

import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './Routes';
import { SnackbarAlert } from './components/';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <Routes />
                <SnackbarAlert />
            </Router>
        </ThemeProvider>
    );
}

export default App;
