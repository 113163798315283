import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 550,
        marginTop: theme.spacing(2),
    },
    edit: {
        cursor: 'pointer',
    },
    seeAll: {
        backgroundColor: theme.palette.gray,
        borderRadius: 10,
        padding: '2px 8px',
        cursor: 'pointer',
    },
    sticky: {
        maxHeight: '70vh',
    },
}));

export const PackageList = ({
    packageList,
    handleModalTaskOpen,
    handleUpdatePackage,
    isLoading,
}) => {
    const classes = useStyles();

    if (isLoading || !packageList?.length) return false;

    return (
        <TableContainer className={classes.sticky}>
            <Table className={classes.table} stickyHeader aria-label='sticky table'>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Tasks</TableCell>
                        <TableCell align='right'>Actions</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {packageList?.map((pkg) => (
                        <TableRow key={pkg._id}>
                            <TableCell component='th' scope='row'>
                                {pkg.name}
                            </TableCell>
                            <TableCell>${Number(pkg?.cost || '').toFixed(2)}</TableCell>
                            <TableCell>
                                <span
                                    className={classes.seeAll}
                                    onClick={handleModalTaskOpen.bind(this, pkg.tasks)}
                                >
                                    {(pkg?.tasks && ' See All') || 'Not Found'}
                                </span>
                            </TableCell>
                            <TableCell align='right' className={classes.edit}>
                                <EditIcon onClick={handleUpdatePackage.bind(this, pkg)} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

PackageList.propTypes = {
    packageList: PropTypes.array.isRequired,
    handleModalTaskOpen: PropTypes.func.isRequired,
    handleUpdatePackage: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};
