import { CUSTOMER_LIST_START, CUSTOMER_EDIT_START, CREATE_NEW_CUSTOMER_START } from '../actionType';

export const customerListAction = (pageNumber, size) => ({
    type: CUSTOMER_LIST_START,
    pageNumber,
    size,
});

export const createCustomerAction = (data) => ({
    type: CREATE_NEW_CUSTOMER_START,
    data,
});

export const editCustomerAction = (data) => ({
    type: CUSTOMER_EDIT_START,
    data,
    id: data._id,
});
