import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Typography, Box, Grid, makeStyles, Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import DoneIcon from '@material-ui/icons/Done';
import { Helmet } from 'react-helmet';

import { Modal, Loading, Button, Input } from '../../components/';
import { Packages } from './components/Packages/packages';
import { Tasks } from './components/Tasks/Tasks';

import {
    createTaskAction,
    isTaskModalAction,
    editTaskAction,
    selectedTaskAction,
    addSelectedTaskAction,
    delSelectedTaskAction,
    taskIsLoadingAction,
} from '../../Redux/actions/taskAction';
import {
    createPackageAction,
    editPackageAction,
    packageIsLoadingAction,
} from '../../Redux/actions/packageAction';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },

    container: {
        width: 400,
        padding: '20px',
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    close: {
        backgroundColor: theme.palette.primary.main,
        borderRadius: 20,
        color: theme.palette.common.white,
        cursor: 'pointer',
    },
    description: {
        fontSize: '12px',
        marginTop: '-10px',
    },
    list: {
        marginTop: theme.spacing(2),
    },
    task: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '10px auto',
    },
    cost: {
        fontSize: 18,
        fontWeight: 700,
        display: 'flex',
        alignItems: 'center',
    },
    icon: {
        color: theme.palette.green,
        marginLeft: theme.spacing(1),
    },
    bold: {
        fontWeight: 800,
    },
    form: {},
    taskadded: {
        border: `1px solid rgba(0, 0, 0, 0.23)`,
        marginTop: 15,
        borderRadius: 4,
        padding: 10,
        minHeight: 100,
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        background: theme.palette.rejected.secondary,
        padding: '2px 4px',
        margin: '5px 5px 0 0',
    },
    tskXIcon: {
        color: theme.palette.primary.main,
    },
}));

export const View = ({
    packageList,
    tasks,
    taskForDropdown,
    handleModalTaskOpen,
    handleModalClose,
    openModalAddTask,
    isTaskModal,
    selectedTasks,
    packageIsLoading,
    taskIsLoading,
    selectedSingleTask,
    setSelectedSingleTask,
    selectedSinglePackage,
    setSelectedSinglePackage,
    handleAddNewPackage,
    handlePKGPagination,
    handleTSKPagination,
    handlePKGSearch,
    searchPKGValue,
    handleTSKSearch,
    searchTSKValue,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    // For Task
    const taskRef = useRef(null);
    const priceRef = useRef(null);
    const descriptionRef = useRef(null);

    const [taskError, setTaskError] = useState(false);
    const [priceError, setPriceError] = useState();
    const [descriptionError, setDescriptionError] = useState();

    // For Package
    const packageRef = useRef(null);
    const pkgPriceRef = useRef(null);
    const [tskDropdown, setTskDropdown] = useState('');

    const [packageError, setPackageError] = useState(false);
    const [pkgPriceError, setPkgPriceError] = useState();
    const [dropdownError, setDropdownError] = useState();

    const cleanUp = () => {
        handleModalClose();
        setTskDropdown('');
    };

    const tasksInputFields = {
        taskName: {
            elementType: 'input',
            placeholder: 'Task Name',
            ref: taskRef,
            error: taskError,
            defaultValue: selectedSingleTask?.name,
        },
        price: {
            elementType: 'input',
            placeholder: 'Price',
            ref: priceRef,
            error: priceError,
            defaultValue: selectedSingleTask?.cost,
        },
        description: {
            elementType: 'input',
            placeholder: 'Description',
            ref: descriptionRef,
            error: descriptionError,
            defaultValue: selectedSingleTask?.description,
        },
    };

    const packagesInputFields = {
        packageName: {
            elementType: 'input',
            placeholder: 'Package Name',
            ref: packageRef,
            error: packageError,
            defaultValue: selectedSinglePackage?.name,
        },
        price: {
            elementType: 'input',
            placeholder: 'Price',
            ref: pkgPriceRef,
            error: pkgPriceError,
            defaultValue: selectedSinglePackage?.cost,
        },
        selectTask: {
            elementType: 'select',
            label: 'Select a Task',
            value: tskDropdown,
            list: taskForDropdown,
            ref: descriptionRef,
            error: dropdownError,
            handler: (e) => {
                setTskDropdown(e.target.value);
                dispatch(addSelectedTaskAction(e.target.value));
            },
        },
    };

    const handleSubmit = (e, _id) => {
        e.preventDefault();

        const task = taskRef?.current?.value || setTaskError(true);
        const price = priceRef?.current?.value || setPriceError(true);
        const description = descriptionRef?.current?.value || setDescriptionError(true);

        task && setTaskError(false);
        price && setPriceError(false);
        description && setDescriptionError(false);

        if (!task || !price || !description) return false;

        dispatch(taskIsLoadingAction(true));

        /** if the form is updateTask */
        if (isTaskModal?.modal === 'UpdateTask') {
            dispatch(
                editTaskAction({
                    _id: selectedSingleTask._id,
                    name: task,
                    description: description,
                    cost: price,
                })
            );
            return;
        }

        dispatch(
            createTaskAction({
                name: task,
                description: description,
                cost: price,
            })
        );
    };

    const handleSubmitPackage = (e) => {
        e.preventDefault();

        const name = packageRef?.current?.value || setPackageError(true);
        const price = pkgPriceRef?.current?.value || setPkgPriceError(true);
        const tasks = selectedTasks.length || setDropdownError(true);

        name && setPackageError(false);
        price && setPkgPriceError(false);
        tasks && setDropdownError(false);

        if (!name || !price || !tasks) return false;

        dispatch(packageIsLoadingAction(true));

        if (isTaskModal?.modal === 'updatePackage') {
            dispatch(
                editPackageAction({
                    _id: selectedSinglePackage._id,
                    name,
                    cost: price,
                    tasks: selectedTasks.map((tsk) => tsk._id),
                })
            );
            return;
        }

        dispatch(
            createPackageAction({
                name,
                cost: price,
                tasks: selectedTasks.map((tsk) => tsk._id),
            })
        );
    };

    const handleUpdateTask = (singleTask) => {
        setSelectedSingleTask(singleTask);
        dispatch(isTaskModalAction({ modal: 'UpdateTask' }));
    };

    const handleUpdatePackage = (singlePkg) => {
        setSelectedSinglePackage(singlePkg);
        dispatch(isTaskModalAction({ modal: 'updatePackage' }));
        dispatch(packageIsLoadingAction(true));
        dispatch(selectedTaskAction(singlePkg?.tasks));
    };

    const handleDeleteTask = (tskName) => {
        dispatch(delSelectedTaskAction(tskName._id));
    };

    /** Modal For Create Task/package, edit */
    let modalBody;
    if (isTaskModal?.modal === 'ShowTasks')
        modalBody = (
            <Box className={classes.list}>
                <Loading isLoading={packageIsLoading} />
                {packageIsLoading ||
                    selectedTasks?.map((tsk) => (
                        <>
                            <Box className={classes.task} key={tsk._id}>
                                <Box>
                                    <Typography variant='h6' gutterBottom>
                                        {tsk.name || 'Task #1'}
                                    </Typography>
                                    <Typography className={classes.description}>
                                        {tsk.description || 'Task Description'}
                                    </Typography>
                                </Box>
                                <Typography className={classes.cost}>
                                    {tsk.cost || '$0.00'} <DoneIcon className={classes.icon} />
                                </Typography>
                            </Box>
                            <Divider />
                        </>
                    ))}
            </Box>
        );
    else if (isTaskModal?.modal === 'AddNewTask' || isTaskModal?.modal === 'UpdateTask')
        modalBody = (
            <Box className={classes.list}>
                <form
                    className={classes.form}
                    noValidate
                    autoComplete='off'
                    onSubmit={handleSubmit}
                >
                    <Grid container>
                        {Object.keys(tasksInputFields).map((key, i, array) => (
                            <Grid item xs={12} key={key}>
                                <Input fields={tasksInputFields[key]} width='360px' />
                            </Grid>
                        ))}
                    </Grid>

                    <Button
                        title={isTaskModal?.modal === 'AddNewTask' ? 'Add' : 'save'}
                        width='100%'
                        marginBottom='0'
                        marginTop='30px'
                        isLoading={taskIsLoading}
                    />
                </form>
            </Box>
        );
    else if (isTaskModal?.modal === 'updatePackage' || isTaskModal?.modal === 'AddPackage')
        modalBody = (
            <Box className={classes.list}>
                <form
                    className={classes.form}
                    noValidate
                    autoComplete='off'
                    onSubmit={handleSubmitPackage}
                >
                    <Grid container>
                        {Object.keys(packagesInputFields).map((key, i, array) => (
                            <Grid item xs={12} key={key}>
                                <Input
                                    fields={packagesInputFields[key]}
                                    width='360px'
                                    selectWidth='360px'
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Box className={classes.taskadded}>
                        {selectedTasks?.map((tsk) => (
                            <Chip
                                key={tsk.name}
                                label={tsk.name}
                                onDelete={handleDeleteTask.bind(this, tsk)}
                                className={classes.chip}
                                classes={{ deleteIcon: classes.tskXIcon }}
                            />
                        ))}
                    </Box>

                    <Button
                        title={isTaskModal?.modal === 'updatePackage' ? 'Save' : 'Add'}
                        width='100%'
                        marginBottom='0'
                        marginTop='30px'
                        isLoading={packageIsLoading}
                    />
                </form>
            </Box>
        );

    return (
        <>
            <Helmet title='Packages & Tasks - TRKRBox' />

            <Modal open={!!isTaskModal?.modal} handleClose={cleanUp}>
                <Box className={classes.container}>
                    <Box className={classes.modalHeader}>
                        <Typography variant='h5' className={classes.bold}>
                            {isTaskModal?.modal === 'AddNewTask' && 'Add New Task'}
                            {isTaskModal?.modal === 'UpdateTask' && 'Edit Task'}
                            {isTaskModal?.modal === 'updatePackage' && 'Edit Package'}
                            {isTaskModal?.modal === 'AddPackage' && 'Add New Package'}
                            {isTaskModal?.modal === 'ShowTasks' && 'Package Tasks'}
                        </Typography>
                        <CloseIcon className={classes.close} onClick={handleModalClose} />
                    </Box>

                    {modalBody}
                </Box>
            </Modal>

            <div className={classes.root}>
                <Grid container spacing={3}>
                    <Packages
                        isLoading={packageIsLoading}
                        packageList={packageList}
                        handleModalTaskOpen={handleModalTaskOpen}
                        handleUpdatePackage={handleUpdatePackage}
                        handleAddNewPackage={handleAddNewPackage}
                        handlePagination={handlePKGPagination}
                        handleSearch={handlePKGSearch}
                        searchValue={searchPKGValue}
                    />
                    <Tasks
                        isLoading={taskIsLoading}
                        tasks={tasks}
                        openModalAddTask={openModalAddTask}
                        handleUpdateTask={handleUpdateTask}
                        handleTSKPagination={handleTSKPagination}
                        handleSearch={handleTSKSearch}
                        searchValue={searchTSKValue}
                    />
                </Grid>
            </div>
        </>
    );
};

View.proTypes = {
    packageList: PropTypes.array.isRequired,
    tasks: PropTypes.array.isRequired,
    taskForDropdown: PropTypes.array.isRequired,
    isTaskModal: PropTypes.any,
    handleModalTaskOpen: PropTypes.func.isRequired,
    handleModalClose: PropTypes.func.isRequired,
    selectedTasks: PropTypes.object.isRequired,
    taskIsLoading: PropTypes.bool.isRequired,
    packageIsLoading: PropTypes.bool.isRequired,
    selectedSingleTask: PropTypes.any,
    setSelectedSingleTask: PropTypes.any,
    selectedSinglePackage: PropTypes.any,
    setSelectedSinglePackage: PropTypes.any,
    handleAddNewPackage: PropTypes.func.isRequired,
    handlePKGPagination: PropTypes.func.isRequired,
    handleTSKPagination: PropTypes.func.isRequired,
    handlePKGSearch: PropTypes.func.isRequired,
    searchPKGValue: PropTypes.string.isRequired,
    handleTSKSearch: PropTypes.func.isRequired,
    searchTSKValue: PropTypes.string.isRequired,
};
