import {
    FORGOT_PASS_START,
    FORGOT_PASS_SAVE,
    IS_RESET_TOKEN_LOADING,
    SHOW_FORGOT_MODAL,
} from '../actionType';

export const forgotPassAction = (data) => ({
    type: FORGOT_PASS_START,
    data,
});

export const saveNewPassAction = (data, method) => ({
    type: FORGOT_PASS_SAVE,
    data,
    method,
});

export const showModalAction = (showModal) => ({
    type: SHOW_FORGOT_MODAL,
    showModal,
});

export const isResetTokenLoadingAction = (isResetTokenLoading) => ({
    type: IS_RESET_TOKEN_LOADING,
    isResetTokenLoading,
});
