import { get } from 'lodash';
import { IS_RESET_TOKEN_VALID, SHOW_FORGOT_MODAL, IS_RESET_TOKEN_LOADING } from '../actionType';

const initialState = {
    isResetTokenValid: { open: '', message: '' },
    isResetTokenLoading: true,
    showModal: {
        open: false,
        message: '',
    },
};

export const forgotPasswordReducer = (state = initialState, action) => {
    switch (action.type) {
        case IS_RESET_TOKEN_VALID:
            return { ...state, isResetTokenValid: get(action, 'isResetTokenValid') };

        case SHOW_FORGOT_MODAL:
            return { ...state, showModal: get(action, 'showModal') };

        case IS_RESET_TOKEN_LOADING:
            return { ...state, isResetTokenLoading: get(action, 'isResetTokenLoading') };
        default:
            return state;
    }
};
