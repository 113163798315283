import {
    PACKAGE_LIST_START,
    CREATE_PACKAGE,
    EDIT_PACKAGE_START,
    PACKAGE_IS_LOADING,
} from '../actionType';

export const packageListAction = (pageNumber, size) => ({
    type: PACKAGE_LIST_START,
    pageNumber,
    size,
});

export const createPackageAction = (data) => ({
    type: CREATE_PACKAGE,
    data,
});

export const editPackageAction = (data) => ({
    type: EDIT_PACKAGE_START,
    data,
});

export const packageIsLoadingAction = (isLoading) => ({
    type: PACKAGE_IS_LOADING,
    isLoading,
});
