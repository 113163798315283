import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { tokenSelector } from '../../Redux/selector/';

export const RouteWithLayout = ({ layout: Layout, component: Component }, ...rest) => {
    const token = useSelector((state) => tokenSelector(state));

    return (
        <Route
            {...rest}
            render={({ location }) =>
                token ? (
                    <Layout>
                        <Component />
                    </Layout>
                ) : (
                    <Redirect to={{ pathname: '/', state: { from: location } }} />
                )
            }
        />
    );
};

RouteWithLayout.prototype = {};
