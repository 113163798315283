import { Button as Btn, makeStyles } from '@material-ui/core';
import LoopIcon from '@material-ui/icons/Loop';
import PropTypes from 'prop-types';

// Styling

const useStyles = makeStyles((theme) => ({
    button: {
        width: ({ width }) => width,
        height: '40px',
        margin: `10px auto 20px`,
        marginBottom: ({ marginBottom }) => marginBottom,
        marginTop: ({ marginTop }) => marginTop,
        marginLeft: ({ marginLeft }) => marginLeft,
        marginRight: ({ marginRight }) => marginRight,
        borderRadius: 20,
        fontWeight: 600,
    },
    icon: {
        animation: '$rotateIcon 1s infinite linear',
    },
    '@keyframes rotateIcon': {
        '0%': {
            transform: 'rotate(0)',
        },
        '100%': {
            transform: 'rotate(359deg)',
        },
    },
}));

export const Button = ({
    title,
    isLoading,
    width = '90%',
    height = '40px',
    marginBottom,
    marginTop,
    marginLeft,
    marginRight,
    onClick,
    icon,
}) => {
    const classes = useStyles({ width, height, marginBottom, marginTop, marginLeft, marginRight });

    return (
        <Btn
            className={classes.button}
            variant='contained'
            color='primary'
            type='submit'
            onClick={onClick}
        >
            {icon && icon}
            {isLoading ? <LoopIcon className={classes.icon} /> : title}
        </Btn>
    );
};

Button.propTypes = {
    title: PropTypes.string.isRequired,
    isLoading: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    onClick: PropTypes.func,
    marginBottom: PropTypes.string,
    marginLeft: PropTypes.string,
    marginRight: PropTypes.string,
    icon: PropTypes.element,
    marginTop: PropTypes.string,
};
