import { Box, makeStyles, useTheme } from '@material-ui/core';
import { TraceSpinner } from 'react-spinners-kit';

import PropTypes from 'prop-types';

// Styling
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: ({ width }) => width || '100%',
        height: ({ height }) => height || '300px',
    },
}));

export const Loading = ({ isLoading, width, height }) => {
    const classes = useStyles({ width, height });
    const theme = useTheme();

    if (!isLoading) return false;

    return (
        <Box className={classes.root}>
            <TraceSpinner size={30} frontColor={theme.palette.primary.main} loading={isLoading} />
        </Box>
    );
};

Loading.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    width: PropTypes.string,
    height: PropTypes.string,
};
