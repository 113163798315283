import { makeStyles, InputBase, fade } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

// Styling

const useStyles = makeStyles((theme) => ({
    search: {
        marginTop: ({ marginTop }) => marginTop,
        marginBottom: ({ marginBottom }) => marginBottom,
        marginLeft: ({ marginLeft }) => marginLeft,
        marginRight: ({ marginRight }) => marginRight,
        position: 'relative',
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        width: ({ width }) => width || '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

export const SearchField = ({
    value,
    onChange,
    marginRight,
    marginLeft,
    marginTop,
    marginBottom,
    width,
}) => {
    const classes = useStyles({ marginRight, marginLeft, marginTop, marginBottom, width });

    return (
        <div className={classes.search}>
            <div className={classes.searchIcon}>
                <SearchIcon />
            </div>
            <InputBase
                placeholder='Search…'
                value={value}
                onChange={onChange}
                onKeyPress={(event) => {
                    if (event.key === 'Enter')
                        onChange({
                            target: {
                                value,
                            },
                        });
                }}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
            />
        </div>
    );
};

SearchField.prototype = {
    marginRight: PropTypes.string,
    marginLeft: PropTypes.string,
    marginTop: PropTypes.string,
    marginBottom: PropTypes.string,
    width: PropTypes.string,
};
