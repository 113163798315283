import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
    isLoadingAction,
    isModalCreateUserAction,
    isModalEditUserAction,
} from '../../Redux/actions/utilityActions';
import {
    isLoadingSelector,
    isModalCreateUserSelector,
    isModalEditUserSelector,
    customerListSelector,
} from '../../Redux/selector';
import { customerListAction } from '../../Redux/actions/customerAction';
import { searchAction } from '../../Redux/actions/searchAction';
import { CUSTOMERS_SEARCH } from '../../utils/urls';
import { CUSTOMER_LIST_COMPLETE } from '../../Redux/actionType';

import { View } from './view';

export const Customer = () => {
    const dispatch = useDispatch();
    const [userObj, setUserObj] = useState();
    const [searchValue, setSearchValue] = useState('');

    const customerList = useSelector((state) => customerListSelector(state));
    const isLoading = useSelector((state) => isLoadingSelector(state));
    const isModalCreateUser = useSelector((state) => isModalCreateUserSelector(state));
    const isModalEditUser = useSelector((state) => isModalEditUserSelector(state));

    useEffect(() => {
        if (!customerList?.items?.length) {
            dispatch(isLoadingAction(true));
            dispatch(customerListAction(1, 10));
        }
    }, [customerList]);

    const openModalHandler = () => {
        dispatch(isModalCreateUserAction(true));
    };

    const handleCloseCreateUser = () => {
        dispatch(isModalCreateUserAction(false));
    };

    const handleEditUser = (obj) => {
        setUserObj(obj);
        dispatch(isModalEditUserAction(true));
    };

    const handleEditClose = () => {
        dispatch(isModalEditUserAction(false));
    };

    const paginationHandler = (event, value) => {
        dispatch(customerListAction(value, 10));
    };

    const handleSearch = (e) => {
        const query = e.target.value;
        setSearchValue(query);
        dispatch(isLoadingAction(true));

        /**
         * when query is empty, fetch all data from remote
         */
        if (!query) {
            dispatch(customerListAction(1, 10));
            return;
        }

        dispatch(searchAction(CUSTOMERS_SEARCH, query, CUSTOMER_LIST_COMPLETE));
    };

    return (
        <View
            isLoadingAction={isLoadingAction}
            customerList={customerList}
            userObj={userObj}
            isLoading={isLoading}
            isModalCreateUser={isModalCreateUser}
            isModalEditUser={isModalEditUser}
            openModalHandler={openModalHandler}
            handleCloseCreateUser={handleCloseCreateUser}
            handleEditUser={handleEditUser}
            handleEditClose={handleEditClose}
            paginationHandler={paginationHandler}
            handleSearch={handleSearch}
            searchValue={searchValue}
        />
    );
};
