import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import SvgIcon from '@material-ui/core/SvgIcon';
import Box from '@material-ui/core/Box';

import { ReactComponent as carIcon } from '../../../assets/images/icon_total_car.svg';
import { ReactComponent as dealIcon } from '../../../assets/images/icon_total_deals.svg';
import { ReactComponent as commIcon } from '../../../assets/images/icon_total_comm.svg';
import { ReactComponent as invoiceIcon } from '../../../assets/images/icon_total_invoice.svg';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 90,
        // minWidth: 200,
        display: 'flex',
        flexGrow: 1,
        position: 'relative',
        cursor: 'pointer',
    },

    selectedTab: {
        background: '#F2F2F2',
    },

    info: {
        marginTop: '13px',
        display: 'flex',
        flexDirection: 'column',
    },
    svgRoot: { width: '64px', height: '64px', margin: '13px', boxSizing: 'border-box' },
    total: {
        fontFamily: 'MyriadPro',
        marginBottom: '0',
        marginTop: '4px',
        fontWeight: '800',
        fontSize: '30px',
        color: theme.palette.green,
    },
    name: {
        fontWeight: '600',
        [theme.breakpoints.down('lg')]: {
            fontSize: '12px',
            marginTop: '3px',
        },

        [theme.breakpoints.down('md')]: {
            fontSize: '16px',
            marginTop: '0',
        },
    },
    impression: {
        position: 'absolute',
        right: '8px',
        bottom: '6px',
    },
    impressionNumber: {
        position: 'absolute',
        right: '23px',
    },
    green: { color: theme.palette.green },
    red: { color: theme.palette.primary.main },
}));

export const CountBox = ({ data, onBoxHandler, selectedBoxTab }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));
    const matchMd = useMediaQuery(theme.breakpoints.up('md'));

    const boxInfo = [
        {
            icon: carIcon,
            name: 'Total Car',
        },
        {
            icon: dealIcon,
            name: 'Total Deals',
        },
        {
            icon: invoiceIcon,
            name: 'Total Invoice',
        },
        {
            icon: commIcon,
            name: 'Total Commision',
        },
    ];

    return (
        <Grid container className={classes.root} spacing={2}>
            {data?.map((box, index) => (
                <Grid
                    key={box.name}
                    item
                    xs={12}
                    sm={4}
                    md={3}
                    onClick={onBoxHandler.bind(null, box.name)}
                >
                    <Paper
                        className={clsx(classes.paper, {
                            [classes.selectedTab]: selectedBoxTab === box.name.toLowerCase(),
                        })}
                    >
                        <Box>
                            <SvgIcon
                                classes={{ root: classes.svgRoot }}
                                component={boxInfo[0].icon}
                                viewBox='0 0 64 64'
                            />
                        </Box>
                        <Box className={classes.info}>
                            <Typography
                                component='span'
                                variant='h4'
                                gutterBottom
                                className={classes.total}
                            >
                                {new Intl.NumberFormat('en-US', {
                                    style: 'currency',
                                    currency: 'USD',
                                    minimumFractionDigits: 2,
                                }).format(box.amount)}
                            </Typography>
                            <Typography
                                component='span'
                                variant='subtitle1'
                                gutterBottom
                                className={classes.name}
                            >
                                {matches && matchMd && box.name === 'Total Commision'
                                    ? 'Total Comm'
                                    : 'Total ' + box.name + ' invoices: ' + box.invoices}
                            </Typography>
                        </Box>
                    </Paper>
                </Grid>
            ))}
        </Grid>
    );
};

CountBox.propTypes = {
    data: PropTypes.array,
    onBoxHandler: PropTypes.func.isRequired,
    selectedBoxTab: PropTypes.string.isRequired,
};
