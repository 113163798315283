import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import {
    invoiceListSelector,
    isLoadingSelector,
    isModalSendInvoiceSelector,
    isLoadingModalSelector,
} from '../../Redux/selector';
import { invoiceListAction, updateInvoiceStatusAction } from '../../Redux/actions/invoiceAction';
import { searchAction } from '../../Redux/actions/searchAction';
import { INVOICES_SEARCH } from '../../utils/urls';
import { INVOICE_LIST_COMPLETE } from '../../Redux/actionType';
import {
    isLoadingAction,
    isLoadingModalAction,
    isModalSendInvoiceAction,
} from '../../Redux/actions/utilityActions';

import { View } from './view';

export const Invoices = () => {
    const [searchValue, setSearchValue] = useState('');
    const dispatch = useDispatch();
    const isModalSendInvoice = useSelector((state) => isModalSendInvoiceSelector(state));
    const invoicesList = useSelector((state) => invoiceListSelector(state));
    const isLoading = useSelector((state) => isLoadingSelector(state));
    const isLoadingModal = useSelector((state) => isLoadingModalSelector(state));
    const [emailInfoObj, setEmailInfoObj] = useState();
    const now = dayjs();
    const firstDayOfMonth = now.startOf('month').format('YYYY-MM-DD');

    useEffect(() => {
        dispatch(invoiceListAction(firstDayOfMonth, 1, 10));
    }, []);

    const handlePagination = (event, value) => {
        dispatch(invoiceListAction(firstDayOfMonth, value, 10));
    };

    const handleOnChange = (event, id) => {
        dispatch(updateInvoiceStatusAction(id, event.target.value, false));
    };

    const updateOpenDateInInvoice = (id, status, date) => {
        dispatch(updateInvoiceStatusAction(id, status, false, date));
    };

    const handleSearch = (e) => {
        const query = e.target.value;

        setSearchValue(query);
        dispatch(isLoadingAction(true));

        /**
         * when query is empty, fetch all the data from remote
         */
        if (!query) {
            dispatch(invoiceListAction(firstDayOfMonth, 1, 10));
            return;
        }

        dispatch(searchAction(INVOICES_SEARCH, query, INVOICE_LIST_COMPLETE));
    };

    const handleSendInvoiceOpen = (obj) => {
        setEmailInfoObj({
            ...emailInfoObj,
            subject: 'Vip Auto Detail Invoice #' + obj?.number,
            _id: obj?._id,
            to: obj?.contact_email || '',
        });
        dispatch(isModalSendInvoiceAction(true));
    };

    const handleSendInvoiceClose = () => {
        dispatch(isModalSendInvoiceAction(false));
    };

    return (
        <View
            invoicesList={invoicesList}
            handlePagination={handlePagination}
            isLoading={isLoading}
            handleOnChange={handleOnChange}
            handleSearch={handleSearch}
            searchValue={searchValue}
            updateOpenDateInInvoice={updateOpenDateInInvoice}
            handleSendInvoiceOpen={handleSendInvoiceOpen}
            handleSendInvoiceClose={handleSendInvoiceClose}
            isModalSendInvoice={isModalSendInvoice}
            emailInfoObj={emailInfoObj}
            isLoadingAction={isLoadingAction}
            isLoadingModalAction={isLoadingModalAction}
            isLoadingModal={isLoadingModal}
        />
    );
};
