import {
    IS_LOADING,
    IS_MODAL_CREATE_USER,
    IS_MODAL_EDIT_USER,
    SNACBAR,
    IS_MODAL_SEND_INVOICE,
    IS_LOADING_MODAL,
} from '../actionType';

export const snackbarAction = (snackBar) => ({
    type: SNACBAR,
    snackBar,
});

export const isLoadingAction = (isLoading) => ({
    type: IS_LOADING,
    isLoading,
});

export const isLoadingModalAction = (isLoadingModal) => ({
    type: IS_LOADING_MODAL,
    isLoadingModal,
});

export const isModalCreateUserAction = (isModal) => ({
    type: IS_MODAL_CREATE_USER,
    isModal,
});

export const isModalEditUserAction = (isModal) => ({
    type: IS_MODAL_EDIT_USER,
    isModal,
});

export const isModalSendInvoiceAction = (isModal) => ({
    type: IS_MODAL_SEND_INVOICE,
    isModal,
});
