const isBrowser = window !== 'undefined';

export const setLocalStorage = (value, name = GET_TOKEN) =>
    isBrowser && localStorage.setItem(name, value);

export const getLocalStorage = (name = GET_TOKEN) => isBrowser && localStorage.getItem(name);

export const rmLocalStorage = (name = GET_TOKEN) => isBrowser && localStorage.removeItem(name);

export const GET_TOKEN = 'trkrboxX_access_token';
export const REFRESH_TOKEN = 'trkrboxX_refresh_token';
export const ORGANIZATION = 'trkrboxX_oranization';
export const EMAIL = 'trkrboxX_access_email';
