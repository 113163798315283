import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import { isLoadingForSheetSelector } from '../../Redux/selector';
import { getInvoicesForSheetsAction } from '../../Redux/actions/invoiceAction';

import { View } from './view';

export const Report = () => {
    const now = dayjs();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => isLoadingForSheetSelector(state));
    const firstDayOfMonth = dayjs().startOf('month').format('YYYY-MM-DD');
    const [startDate, setStartDate] = useState(firstDayOfMonth);
    const [endDate, setEndDate] = useState(now.format('YYYY-MM-DD'));

    const startDateHandler = (value) => {
        setStartDate(dayjs(value).format('YYYY-MM-DD'));
    };
    const endDateHandler = (value) => {
        setEndDate(dayjs(value).format('YYYY-MM-DD'));
    };

    const onExportSheetHandler = (event) => {
        event.preventDefault();
        dispatch(getInvoicesForSheetsAction(startDate, endDate));
    };

    return (
        <View
            startDate={startDate}
            endDate={endDate}
            startDateHandler={startDateHandler}
            endDateHandler={endDateHandler}
            onExportSheetHandler={onExportSheetHandler}
            isLoading={isLoading}
        />
    );
};
