import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Button, Input, Modal } from '../../../../components';

import { sendInvoiceMailAction } from '../../../../Redux/actions/invoiceAction';
import { emailValidation } from '../../../../utils/regex';
// Styling

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px 20px',
    },
    bold: {
        fontWeight: 600,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    icon: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: 20,
        cursor: 'pointer',
        fontSize: 18,
    },
}));

export const SendInvoice = ({ isModal, handleClose, isLoading, isLoadingAction, InfoEmail }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const toRef = useRef(null);
    const subjectRef = useRef(null);
    const messageRef = useRef(null);

    const [toError, setToError] = useState({ error: false, helperText: '' });
    //const [subjectError, setSubjectError] = useState(false);
    //const [messageError, setMessageError] = useState(false);
    const inputFields = {
        to: {
            elementType: 'input',
            placeholder: 'To',
            ref: toRef,
            error: toError.error,
            defaultValue: InfoEmail?.to,
            helperText: toError.helperText,
        },
        subject: {
            elementType: 'input',
            placeholder: 'Subject (Optional)',
            ref: subjectRef,
            defaultValue: InfoEmail?.subject,
            error: false,
        },
        message: {
            elementType: 'multiline',
            placeholder: 'Message (Optional)',
            ref: messageRef,
            defaultValue: InfoEmail?.message,
            error: false,
        },
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const destinations = toRef?.current?.value?.toLowerCase();
        const subject = subjectRef?.current?.value;
        const message = messageRef?.current?.value;
        const _id = InfoEmail?._id;
        /** if it has value, false the error */
        if (!destinations) return setToError({ helperText: 'Required field', error: true });
        const arrayEmails = destinations?.split(',');
        if (arrayEmails?.length <= 1) {
            if (!emailValidation.test(destinations)) {
                return setToError({
                    helperText: 'Invalid email, example:email@example.com',
                    error: true,
                });
            }
        }

        for (let i = 0; i < arrayEmails?.length; i++) {
            if (!emailValidation.test(arrayEmails[i]?.toLowerCase())) {
                return setToError({
                    helperText:
                        'Invalid group emails, separate emails by commas example: email1@example.com,email2@example.com',
                    error: true,
                });
            }
        }

        setToError({ helperText: '', error: false });
        dispatch(isLoadingAction(true));
        dispatch(
            sendInvoiceMailAction({
                invoice_id: _id,
                receiver: destinations,
                subject: subject,
                message: message,
            })
        );
    };

    return (
        <Modal open={isModal} handleClose={handleClose} borderRadius={10}>
            <Box className={classes.container}>
                <Box className={classes.header}>
                    <Typography className={classes.bold} variant='h6' gutterBottom>
                        Send Invoice
                    </Typography>
                    <CloseIcon className={classes.icon} onClick={handleClose} />
                </Box>
                <form
                    className={classes.form}
                    noValidate
                    autoComplete='off'
                    onSubmit={handleSubmit}
                >
                    <Grid container>
                        {Object.keys(inputFields).map((key) => (
                            <Grid item key={key} lg={12} md={12} sm={12} xs={12}>
                                <Input
                                    fields={inputFields[key]}
                                    InfoEmail={InfoEmail}
                                    width='100%'
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Button
                        title='Send'
                        width='100%'
                        marginBottom='0'
                        marginTop='30px'
                        isLoading={isLoading}
                    />
                </form>
            </Box>
        </Modal>
    );
};

SendInvoice.prototype = {
    isModal: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoadingAction: PropTypes.func.isRequired,
    InfoEmail: PropTypes.object,
};
