import { get } from 'lodash';
import { LOGIN_COMPLETE, LOGOUT_COMPLETE, ASSESS_TOKEN, EMAIL } from '../actionType/';

const initialState = {
    access_token: null,
    refresh_token: null,
    organization: null,
};

export const loginReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_COMPLETE:
            return {
                ...state,
                ...action.payload,
            };
        case LOGOUT_COMPLETE:
            return {
                ...state,
                access_token: null,
                refresh_token: null,
                organization: null,
            };

        case ASSESS_TOKEN:
            return {
                ...state,
                access_token: get(action, 'access_token'),
            };

        case EMAIL:
            return { ...state, email: get(action, 'email') };

        default:
            return state;
    }
};
