import { makeStyles, InputBase, Box, fade } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';

import { Button } from '../../components/Button/Button';
import { SearchField } from '../SearchField/searchField';

// Styling

const useStyles = makeStyles((theme) => ({
    form: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(3),
    },
    icon: {
        marginRight: theme.spacing(1),
    },
    search: {
        position: 'relative',
        border: `1px solid ${theme.palette.secondary.main}`,
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        padding: theme.spacing(0, 2),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

export const Filter = ({ btnTitle, openModalHandler, btnWidth, onChange, value }) => {
    const classes = useStyles();

    return (
        <Box className={classes.form}>
            <div>
                <Button
                    title={btnTitle}
                    width={btnWidth}
                    marginBottom='10px'
                    onClick={openModalHandler}
                    icon={<AddIcon className={classes.icon} />}
                >
                    Add New Customer
                </Button>
            </div>

            <div>
                <SearchField onChange={onChange} value={value} />
            </div>
        </Box>
    );
};

Filter.propTypes = {
    btnWidth: PropTypes.string,
    btnTitle: PropTypes.string.isRequired,
    openModalHandler: PropTypes.func.isRequired,
};
