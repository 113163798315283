import { useRef, useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Typography, Link as LinkMaterial, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { Helmet } from 'react-helmet';

import { Form, Loading } from '../../components/';
import { isLoadingAction } from '../../Redux/actions/utilityActions';

import {
    forgotPassAction,
    saveNewPassAction,
    isResetTokenLoadingAction,
    showModalAction,
} from '../../Redux/actions/forgotPasswordAction';
import {
    isResetTokenValidSelector,
    showModalSelector,
    isResetTokenLoading,
} from '../../Redux/selector/';

import { Modal } from '../../components/Modal/Modal';
import { Button } from '../../components/Button/Button';
import { MessageCard } from './components/MessageCard/MessageCard';

// Stylying
const useStyle = makeStyles((theme) => ({
    subtitle: {
        width: '90%',
        margin: '30px auto',
    },
    bottom: { textAlign: 'center' },
    container: {
        padding: '25px 20px 10px',
        width: 400,
        textAlign: 'center',
    },
}));

export const ForgotPassword = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { reset_token } = useParams();
    const classes = useStyle();
    const isResetTokenValid = useSelector((state) => isResetTokenValidSelector(state));
    const showModal = useSelector((state) => showModalSelector(state));
    const isResetTokenLoadingApp = useSelector((state) => isResetTokenLoading(state));

    const emailRef = useRef(null);
    const newPasswordRef = useRef(null);
    const newPasswordConfirmRef = useRef(null);

    const [isRestForm] = useState(reset_token ? 'resetPassword' : 'forgotPassword');
    const [emailError, setEmailError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [passwordCError, setPasswordCError] = useState(false);

    const restFields = {
        email: {
            ref: emailRef,
            label: 'Your Email',
            error: emailError,
            placeholder: 'example@example.com',
        },
    };

    const newPasswordFeilds = {
        password: {
            ref: newPasswordRef,
            label: 'New Password',
            error: passwordError,
            placeholder: 'Password',
        },
        passwordConfirm: {
            ref: newPasswordConfirmRef,
            label: 'Confirm password',
            error: passwordCError,
            placeholder: 'Confirm New Password',
        },
    };

    useEffect(() => {
        if (isRestForm === 'resetPassword') {
            dispatch(isResetTokenLoadingAction(true));
            dispatch(saveNewPassAction(reset_token, 'GET'));
        }
    }, []);

    const forgotPasswordHandler = (event) => {
        event.preventDefault();
        let email = emailRef.current?.value || setEmailError(true);

        emailError && email && setEmailError(false);

        if (!email) return;

        dispatch(isLoadingAction(true));
        dispatch(
            forgotPassAction({
                email,
                organization: 'TRKRBox',
            })
        );
    };

    const handleSubmitSavePassword = (event) => {
        event.preventDefault();
        let password;
        let passwordConfirm;

        password = newPasswordRef.current.value;
        passwordConfirm = newPasswordConfirmRef.current.value;

        if (!password) setPasswordError(true);
        if (!passwordConfirm) setPasswordCError(true);

        /**
         * if the error is true and the value is true, stop showing error.
         */
        passwordError && password && setPasswordError(false);
        passwordCError && passwordConfirm && setPasswordCError(false);

        if (password !== passwordConfirm) {
            setPasswordError(true);
            setPasswordCError(true);
            return;
        }

        if (!password || !passwordConfirm) return;

        dispatch(isLoadingAction(true));
        dispatch(
            saveNewPassAction(
                {
                    password: password,
                    reset_token,
                },
                'POST'
            )
        );
    };

    const handleCloseModal = () => {
        dispatch(isResetTokenLoadingAction({ open: '', message: '' }));
        dispatch(
            showModalAction({
                open: false,
                message: '',
            })
        );

        history.push('/login');
    };

    const renderFormHeader = (
        <Typography className={classes.subtitle} variant='subtitle1' gutterBottom>
            Enter your email address and we will send email with instruction.
        </Typography>
    );

    const renderFormBottom = (
        <>
            <Typography className={classes.bottom}>
                <LinkMaterial component={Link} to='/login'>
                    Back To Login
                </LinkMaterial>
            </Typography>
        </>
    );

    const renderNewPasswordHeader = (
        <Typography className={classes.subtitle} variant='subtitle1' gutterBottom>
            Please Enter your new Password.
        </Typography>
    );

    if (isResetTokenLoadingApp && isRestForm === 'resetPassword')
        return <Loading isLoading={isResetTokenLoadingApp} />;

    let renderForm;

    if (isRestForm === 'forgotPassword')
        renderForm = (
            <Form
                formHeader={renderFormHeader}
                formBottom={renderFormBottom}
                inputFields={restFields}
                buttonTitle='Send'
                formHeight={300}
                handleSubmit={forgotPasswordHandler}
            />
        );

    if (isRestForm === 'resetPassword')
        renderForm = (
            <Form
                formHeader={renderNewPasswordHeader}
                inputFields={newPasswordFeilds}
                buttonTitle='Save New Password'
                formHeight={330}
                handleSubmit={handleSubmitSavePassword}
                marginBottom='29px'
            />
        );

    if (isRestForm === 'resetPassword' && !isResetTokenLoadingApp && isResetTokenValid?.open)
        renderForm = (
            <MessageCard
                isResetTokenValid={isResetTokenValid}
                renderLinkBackToLogin={renderFormBottom}
            />
        );

    return (
        <>
            <Helmet title='Reset password - TRKRBox' />

            <Modal open={showModal?.open} handleClose={handleCloseModal}>
                <Box className={classes.container}>
                    <Typography>{showModal.message}</Typography>
                    <Button title='OK' onClick={handleCloseModal} />
                </Box>
            </Modal>

            {renderForm}
        </>
    );
};
