import { makeStyles, Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { TableUsers } from './components/Table/Table';
import { CreateUser } from './components/CreateUser/createUser';
import { EditUser } from './components/editUser/editUser';
import { isLoadingAction } from '../../Redux/actions/utilityActions';
import { Loading, Filter, NoData, Pagination } from '../../components/';

// Styling

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '15px 20px',
    },
}));

export const View = ({
    userObj,
    userList,
    isLoading,
    isModalCreateUser,
    isModalEditUser,
    openModalHandler,
    handleCloseCreateUser,
    handleEditUser,
    handleEditClose,
    handlePagination,
    handleSearch,
    searchValue,
}) => {
    const classes = useStyles();

    return (
        <>
            <Helmet title='Users List - TRKRBox' />

            <CreateUser
                isModal={isModalCreateUser}
                handleClose={handleCloseCreateUser}
                isLoading={isLoading}
                isLoadingAction={isLoadingAction}
            />

            <EditUser
                isModal={isModalEditUser}
                handleClose={handleEditClose}
                isLoading={isLoading}
                isLoadingAction={isLoadingAction}
                defaultUser={userObj}
            />

            <Paper className={classes.paper}>
                <Typography variant='h6' gutterBottom className={classes.customer}>
                    Users
                </Typography>

                <Filter
                    btnTitle='Add New User'
                    btnWidth='200px'
                    openModalHandler={openModalHandler}
                    onChange={handleSearch}
                    value={searchValue}
                />

                <Loading isLoading={isLoading} />
                <NoData isLoading={isLoading} data={!!userList?.items?.length} />

                <TableUsers
                    users={userList?.items}
                    handleEditUser={handleEditUser}
                    isLoading={isLoading}
                />

                <Pagination count={userList?.total_pages} onChange={handlePagination} />
            </Paper>
        </>
    );
};

View.propTypes = {
    userObj: PropTypes.object,
    userList: PropTypes.object,
    isLoading: PropTypes.bool.isRequired,
    isModalCreateUser: PropTypes.any.isRequired,
    isModalEditUser: PropTypes.any.isRequired,
    openModalHandler: PropTypes.func.isRequired,
    handleCloseCreateUser: PropTypes.func.isRequired,
    handleEditUser: PropTypes.func.isRequired,
    handleEditClose: PropTypes.func.isRequired,
    handlePagination: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
};
