import {
    Typography,
    Grid,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import PropTypes from 'prop-types';

import { Filter, Loading, Pagination } from '../../../../components/';
import { NoData } from '../../../../components/NoData/noData';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '15px 20px',
    },
    table: {
        marginTop: theme.spacing(2),
    },
    edit: {
        cursor: 'pointer',
    },
    sticky: {
        maxHeight: '70vh',
    },
}));

export const Tasks = ({
    tasks,
    openModalAddTask,
    handleUpdateTask,
    isLoading,
    handleTSKPagination,
    handleSearch,
    searchValue,
}) => {
    const classes = useStyles();

    return (
        <Grid item xs={5}>
            <Paper className={classes.paper}>
                <Typography variant='h6' gutterBottom>
                    Tasks
                </Typography>
                <Filter
                    btnTitle='Add Task'
                    btnWidth='145px'
                    openModalHandler={openModalAddTask}
                    onChange={handleSearch}
                    value={searchValue}
                />

                <Loading isLoading={isLoading} />
                <NoData data={!!tasks?.items?.length} isLoading={isLoading} />

                {!isLoading && tasks?.items?.length && (
                    <TableContainer className={classes.sticky}>
                        <Table className={classes.table} stickyHeader aria-label='sticky table'>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Price</TableCell>
                                    <TableCell align='right'>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tasks?.items?.map((tsk) => (
                                    <TableRow key={tsk._id}>
                                        <TableCell component='th' scope='row'>
                                            {tsk.name}
                                        </TableCell>

                                        <TableCell>
                                            {tsk?.cost
                                                ? String(tsk.cost).charAt(0) === '$'
                                                    ? '$' + parseFloat(tsk.cost.slice(1)).toFixed(2)
                                                    : '$' + parseFloat(tsk.cost).toFixed(2)
                                                : '$0.00'}
                                        </TableCell>

                                        <TableCell align='right' className={classes.edit}>
                                            <EditIcon
                                                className={classes.edit}
                                                onClick={handleUpdateTask.bind(this, tsk)}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}

                <Pagination count={tasks?.total_pages} onChange={handleTSKPagination} />
            </Paper>
        </Grid>
    );
};

Tasks.propTypes = {
    tasks: PropTypes.array.isRequired,
    openModalAddTask: PropTypes.func.isRequired,
    handleUpdateTask: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    handleTSKPagination: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
};
