import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { ReactComponent as LogoIcon } from '../../../assets/images/new_logo_white.svg';
import { ReactComponent as homeIcon } from '../../../assets/images/icon_home.svg';
import { ReactComponent as usersIcon } from '../../../assets/images/icon_users.svg';
import { ReactComponent as invoiceIcon } from '../../../assets/images/icon_file_text.svg';
import { ReactComponent as tasksIcon } from '../../../assets/images/icon_tasks_packages.svg';
import { ReactComponent as reportIcon } from '../../../assets/images/icon_export_new.svg';
import { Navbar } from './list';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    toolbar: {
        backgroundColor: theme.palette.secondary.main,
    },
    iconColor: {
        color: theme.palette.white,
    },

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },

    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'space-between',
    },
}));

export const DrawerSideBar = ({ open, handleDrawerClose }) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));

    const navItems = [
        { name: 'Dashboard', icon: homeIcon, to: '/dashboard' },
        { name: 'Users', icon: usersIcon, to: '/users' },
        { name: 'Invoices', icon: invoiceIcon, to: '/invoices' },
        { name: 'Customers', icon: usersIcon, to: '/customers' },
        { name: 'Packages/Tasks', icon: tasksIcon, to: '/packageAndTask' },
        { name: 'Reports', icon: reportIcon, to: '/reports' },
    ];

    return (
        <Drawer
            className={classes.drawer}
            variant={matches ? 'persistent' : 'temporary'}
            anchor='left'
            open={open}
            onClose={handleDrawerClose}
            classes={{
                paper: classes.drawerPaper,
            }}
            ModalProps={{
                keepMounted: true, // Better open performance on mobile.
            }}
        >
            <div className={clsx(classes.drawerHeader, classes.toolbar)}>
                <LogoIcon />

                {!matches && (
                    <IconButton onClick={handleDrawerClose}>
                        {matches || theme.direction === 'ltr' ? (
                            <ChevronLeftIcon className={classes.iconColor} />
                        ) : (
                            <ChevronRightIcon className={classes.iconColor} />
                        )}
                    </IconButton>
                )}
            </div>

            <Divider />

            <Navbar listItem={navItems} />
        </Drawer>
    );
};

DrawerSideBar.propTypes = {
    open: PropTypes.bool.isRequired,
    handleDrawerClose: PropTypes.func.isRequired,
};
