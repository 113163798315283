import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';

import {
    dashboardInfoSelector,
    isLoadingSelector,
    isModalSendInvoiceSelector,
    isLoadingModalSelector,
} from '../../Redux/selector/';
import { dashboardInfoAction } from '../../Redux/actions/dashboardAction';
import { View } from './view';
import {
    isLoadingAction,
    isLoadingModalAction,
    isModalSendInvoiceAction,
} from '../../Redux/actions/utilityActions';
import { updateInvoiceStatusAction } from '../../Redux/actions/invoiceAction';

export const Dashboard = () => {
    const dispatch = useDispatch();
    const dashboardInfo = useSelector((state) => dashboardInfoSelector(state));
    const isModalSendInvoice = useSelector((state) => isModalSendInvoiceSelector(state));
    const isLoading = useSelector((state) => isLoadingSelector(state));
    const isLoadingModal = useSelector((state) => isLoadingModalSelector(state));
    const [data, setData] = useState();
    const [boxData, setBoxData] = useState();
    const [selectedBoxTab, setSelectedBoxTab] = useState('open');
    const [emailInfoObj, setEmailInfoObj] = useState();
    const now = dayjs();
    const today = now.format('YYYY-MM-DD');
    const firstDayOfMonth = now.date(28).subtract(1, 'month').format('YYYY-MM-DD');

    const [startDate, setStartDate] = useState(firstDayOfMonth);
    const [endDate, setEndDate] = useState(now.format('YYYY-MM-DD'));

    useEffect(() => {
        if (now.$D > 27) setEndDate(now.date(27));
    }, []);

    useEffect(() => {
        dispatch(isLoadingAction(true));
        dispatch(dashboardInfoAction(firstDayOfMonth, today));
    }, []);

    /**
     * get the open invoice data
     */
    useEffect(() => {
        setBoxData(
            dashboardInfo.map((info) => {
                for (let key in info)
                    return {
                        name: key,
                        amount: info[key].amount,
                        invoices: info[key].total_invoices,
                    }; // {open: 22}, {due: 33}
            })
        );

        /**
         * get data for specific tab
         */
        dashboardInfo.length &&
            setData(dashboardInfo.filter((info) => info[selectedBoxTab])[0][selectedBoxTab]);
    }, [dashboardInfo]);

    const startDateHandler = (value) => {
        setStartDate(dayjs(value).format('YYYY-MM-DD'));
    };
    const endDateHandler = (value) => {
        setEndDate(dayjs(value).format('YYYY-MM-DD'));
    };

    const handleSendInvoiceOpen = (obj) => {
        setEmailInfoObj({
            ...emailInfoObj,
            subject: 'Vip Auto Detail Invoice #' + obj?.number,
            _id: obj?._id,
            to: obj?.contact_email || '',
        });
        dispatch(isModalSendInvoiceAction(true));
    };

    const handleSendInvoiceClose = () => {
        dispatch(isModalSendInvoiceAction(false));
    };

    const onSearchHandler = (e) => {
        e.preventDefault();
        const sdateFormat = dayjs(startDate).format('YYYY-MM-DD');
        const edateFormat = dayjs(endDate).format('YYYY-MM-DD');
        dispatch(isLoadingAction(true));
        dispatch(dashboardInfoAction(sdateFormat, edateFormat));
    };

    const onBoxHandler = (value) => {
        setSelectedBoxTab(value.toLowerCase());
        setData(dashboardInfo.filter((info) => info[value])[0][value]);
    };

    const handleInvoiceStatusChange = (event, id) => {
        dispatch(updateInvoiceStatusAction(id, event.target.value, true));
    };

    const updateOpenDateInInvoice = (id, status, date) => {
        dispatch(updateInvoiceStatusAction(id, status, true, date));
    };

    return (
        <View
            startDate={startDate}
            endDate={endDate}
            startDateHandler={startDateHandler}
            endDateHandler={endDateHandler}
            onSearchHandler={onSearchHandler}
            isLoading={isLoading}
            data={data}
            boxData={boxData}
            selectedBoxTab={selectedBoxTab}
            onBoxHandler={onBoxHandler}
            handleInvoiceStatusChange={handleInvoiceStatusChange}
            updateOpenDateInInvoice={updateOpenDateInInvoice}
            handleSendInvoiceOpen={handleSendInvoiceOpen}
            handleSendInvoiceClose={handleSendInvoiceClose}
            isModalSendInvoice={isModalSendInvoice}
            emailInfoObj={emailInfoObj}
            isLoadingAction={isLoadingAction}
            isLoadingModalAction={isLoadingModalAction}
            isLoadingModal={isLoadingModal}
        />
    );
};
