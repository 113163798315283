import { Switch, Route, Redirect } from 'react-router-dom';

import {
    SignInView,
    ForgotPasswordView,
    DashboardView,
    UsersView,
    InvoicesView,
    CustomerView,
    PackageAndTaskView,
    InvoicePDFView,
    ReportView,
} from './Views/';
import { MainLayout } from './layout';
import { RouteWithLayout } from './components';

export const Routes = () => {
    return (
        <Switch>
            <Redirect exact from='/' to='/login' />
            <Route exact path='/login'>
                <SignInView />
            </Route>
            <Route exact path={['/forgot-password', '/reset_password/:reset_token']}>
                <ForgotPasswordView />
            </Route>

            <RouteWithLayout
                exact
                path='/dashboard'
                component={DashboardView}
                layout={MainLayout}
            />

            <RouteWithLayout exact path='/users' component={UsersView} layout={MainLayout} />
            <RouteWithLayout exact path='/invoices' component={InvoicesView} layout={MainLayout} />
            <RouteWithLayout exact path='/customers' component={CustomerView} layout={MainLayout} />

            <RouteWithLayout
                exact
                path='/packageAndTask'
                component={PackageAndTaskView}
                layout={MainLayout}
            />

            <Route exact path='/generate-invoice/:id' component={InvoicePDFView} />
            <RouteWithLayout exact path='/reports' component={ReportView} layout={MainLayout} />
            <Route path='*'>
                <h1 style={{ textAlign: 'center', margin: '100px auto' }}>404 NOT_FOUND</h1>
            </Route>
        </Switch>
    );
};
