import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { parseISO } from 'date-fns';
import { DatePicker } from '@material-ui/pickers';

// Styling

const useStyles = makeStyles((theme) => ({
    input: {
        width: ({ width }) => width,
        '& > .MuiInputBase-root': {
            height: 41,
            fontSize: '14px',
        },
        '& > .MuiFormLabel-root': {
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100% !important',
            paddingRight: '18px',
        },
    },
}));

export const DatePickerComponent = ({
    disableFuture,
    clearable,
    openTo,
    value,
    label,
    inputVariant,
    onChange,
    format,
    disabled,
    width,
}) => {
    const classes = useStyles({ width });

    return (
        <DatePicker
            disableFuture={disableFuture}
            clearable={clearable}
            openTo={openTo}
            className={classes.input}
            value={value}
            label={label}
            inputVariant={inputVariant}
            onChange={onChange}
            format={format}
            disabled={disabled}
        />
    );
};

DatePickerComponent.defaultProps = {
    inputVariant: 'outlined',
    format: 'MM/DD/YYYY',
};

DatePickerComponent.propTypes = {
    disableFuture: PropTypes.bool.isRequired,
    clearable: PropTypes.bool.isRequired,
    openTo: PropTypes.string,
    label: PropTypes.string.isRequired.apply,
    inputVariant: PropTypes.string,
    value: PropTypes.any,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    width: PropTypes.string,
};
