import { Typography, Paper } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from 'clsx';
import PropTypes from 'prop-types';

// Stylying
const useStyle = makeStyles((theme) => ({
    errorMessage: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
        background: theme.palette.gray,
    },
    errorMessagePaper: {
        maxWidth: 600,
        minHeight: 300,
        textAlign: 'center',
        padding: theme.spacing(5),
        boxSizing: 'border-box',
    },
    icon: {
        fontSize: '5rem',
        textAlign: 'center',
        paddingBottom: theme.spacing(3),
    },
    iconSuccess: {
        color: theme.palette.active.main,
    },
    iconDanger: {
        color: theme.palette.rejected.main,
    },
    errorInfo: {
        marginBottom: theme.spacing(5),
    },
}));

export const MessageCard = ({ isResetTokenValid, renderLinkBackToLogin }) => {
    const classes = useStyle();

    return (
        <div className={classes.errorMessage}>
            <Paper elevation={1} className={classes.errorMessagePaper}>
                {isResetTokenValid.open === 'error' && (
                    <ErrorIcon
                        fontSize='large'
                        classes={{ fontSizeLarge: clsx(classes.icon, classes.iconDanger) }}
                    />
                )}
                {isResetTokenValid.open === 'success' && (
                    <CheckCircleIcon
                        fontSize='large'
                        classes={{ fontSizeLarge: clsx(classes.icon, classes.success) }}
                    />
                )}
                <Typography className={classes.errorInfo}>{isResetTokenValid?.message}</Typography>
                {renderLinkBackToLogin}
            </Paper>
        </div>
    );
};

MessageCard.propTypes = {
    isResetTokenValid: PropTypes.object.isRequired,
    renderLinkBackToLogin: PropTypes.element,
};
