import {
    INVOICE_PDF_START,
    INVOICE_LIST_START,
    UPDATED_INVOICE_STATUS_START,
    GET_INVOICE_FRO_SHEET,
    IS_LOADING_FOR_SHEET,
    SEND_INVOICE_MAIL_START,
} from '../actionType';

export const invoiceListAction = (date, pageNumber, size) => ({
    type: INVOICE_LIST_START,
    date,
    pageNumber,
    size,
});

export const invoicePDFAction = (id) => ({
    type: INVOICE_PDF_START,
    id,
});

export const updateInvoiceStatusAction = (id, status, forDashboardOnly = true, created_at) => ({
    type: UPDATED_INVOICE_STATUS_START,
    data: { id, status, created_at },
    forDashboardOnly,
});

export const getInvoicesForSheetsAction = (startDate, endDate) => ({
    type: GET_INVOICE_FRO_SHEET,
    startDate,
    endDate,
});

export const isLoadingForSheet = (status) => ({
    type: IS_LOADING_FOR_SHEET,
    isLoading: status,
});

export const sendInvoiceMailAction = (infoEmail) => ({
    type: SEND_INVOICE_MAIL_START,
    infoEmail: infoEmail,
});
