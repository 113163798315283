import { View } from './view';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loginAction } from '../../Redux/actions/loginActions';
import { isLoadingAction } from '../../Redux/actions/utilityActions';
import { tokenSelector } from '../../Redux/selector/';
import { useHistory } from 'react-router-dom';

export const SignInIndex = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const token = useSelector((state) => tokenSelector(state));
    const emailRef = useRef(null);
    const [emailError, setEmailError] = useState(false);
    const passwordRef = useRef(null);
    const [passwordError, setPasswordError] = useState(false);

    const inputFields = {
        email: {
            label: 'Email or Username',
            ref: emailRef,
            error: emailError,
            placeholder: 'example@email.com',
        },
        password: {
            label: 'Password',
            ref: passwordRef,
            error: passwordError,
            placeholder: 'Password',
        },
    };

    useEffect(() => {
        if (token) {
            history.push('/dashboard');
        }
    }, [token]);

    const handleSubmit = (event) => {
        event.preventDefault();
        const email = emailRef.current.value;
        const password = passwordRef.current.value;

        // form validation;
        if (!email) setEmailError(true);
        if (!password) setPasswordError(true);

        emailError && email && setEmailError(false);
        passwordError && password && setPasswordError(false);

        if (!password || !email) return;

        dispatch(isLoadingAction(true));
        dispatch(loginAction({ username: email, password, organization: 'TRKRBox' }));
    };
    return <View handleSubmit={handleSubmit} inputFields={inputFields} />;
};
