import { makeStyles, Paper, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { TableUsers } from './components/Table/Table';
import { CreateUser } from './components/CreateUser/createUser';
import { EditUser } from './components/editUser/editUser';
import { Filter, Pagination } from '../../components/';

import { Loading } from '../../components/Loading/Loading';
import { NoData } from '../../components/NoData/noData';

// Styling

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '15px 20px',
    },
}));

export const View = ({
    customerList,
    isLoading,
    isModalCreateUser,
    isModalEditUser,
    openModalHandler,
    handleCloseCreateUser,
    handleEditUser,
    handleEditClose,
    userObj,
    isLoadingAction,
    paginationHandler,
    handleSearch,
    searchValue,
}) => {
    const classes = useStyles();

    return (
        <>
            <Helmet title='Customers - TRKRBox' />

            <CreateUser
                isModal={isModalCreateUser}
                handleClose={handleCloseCreateUser}
                isLoading={isLoading}
                isLoadingAction={isLoadingAction}
            />

            <EditUser
                isModal={isModalEditUser}
                handleClose={handleEditClose}
                isLoading={isLoading}
                isLoadingAction={isLoadingAction}
                defaultUser={userObj}
            />

            <Paper className={classes.paper}>
                <Typography variant='h6' gutterBottom className={classes.customer}>
                    Customers
                </Typography>
                <Filter
                    btnTitle='Add New Customer'
                    btnWidth='220px'
                    openModalHandler={openModalHandler}
                    onChange={handleSearch}
                    value={searchValue}
                />

                <Loading isLoading={isLoading} />
                <NoData data={!!customerList?.items?.length} isLoading={isLoading} />
                <TableUsers
                    users={customerList?.items}
                    handleEditUser={handleEditUser}
                    isLoading={isLoading}
                />

                <Pagination count={customerList?.total_pages} onChange={paginationHandler} />
            </Paper>
        </>
    );
};

View.propTypes = {
    customerList: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isModalCreateUser: PropTypes.any.isRequired,
    isModalEditUser: PropTypes.any.isRequired,
    openModalHandler: PropTypes.func.isRequired,
    handleCloseCreateUser: PropTypes.func.isRequired,
    handleEditUser: PropTypes.func.isRequired,
    handleEditClose: PropTypes.func.isRequired,
    userObj: PropTypes.object,
    isLoadingAction: PropTypes.func.isRequired,
    paginationHandler: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
};
