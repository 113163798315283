import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    input: {
        boxSizing: 'border-box',
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(1),
        '& > *': {
            width: ({ width }) => width || '270px',
        },
    },
    formControl: {
        width: ({ selectWidth }) => selectWidth || '70.8ch',
        marginTop: theme.spacing(1),
    },
}));

export const Input = ({ fields, selectWidth, width }) => {
    const classes = useStyles({ selectWidth, width });

    switch (fields.elementType) {
        case 'input':
            return (
                <TextField
                    className={classes.input}
                    variant='outlined'
                    type='text'
                    id='standard-basic'
                    label={fields.placeholder}
                    placeholder={fields.placeholder}
                    inputRef={fields.ref}
                    error={fields.error}
                    defaultValue={fields.defaultValue}
                    helperText={fields.helperText}
                    fullWidth
                />
            );

        case 'phone':
            return (
                <TextField
                    className={classes.input}
                    variant='outlined'
                    type='text'
                    id='standard-basic'
                    label={fields.placeholder}
                    placeholder={fields.placeholder}
                    inputRef={fields.ref}
                    error={fields.error}
                    defaultValue={fields.defaultValue}
                    helperText={fields.helperText}
                />
            );

        case 'email':
            return (
                <TextField
                    className={classes.input}
                    variant='outlined'
                    type='email'
                    id='standard-basic'
                    label={fields.placeholder}
                    placeholder={fields.placeholder}
                    inputRef={fields.ref}
                    error={fields.error}
                    defaultValue={fields.defaultValue}
                    disabled={fields.disable}
                    helperText={fields.helperText}
                />
            );

        case 'password':
            return (
                <TextField
                    className={classes.input}
                    variant='outlined'
                    type='password'
                    id='standard-basic'
                    label={fields.placeholder}
                    placeholder={fields.placeholder}
                    inputRef={fields.ref}
                    error={fields.error}
                    helperText={fields.helperText}
                />
            );

        case 'select':
            return (
                <FormControl variant='outlined' className={classes.formControl}>
                    <InputLabel id='demo-simple-select-outlined-label'>{fields.label}</InputLabel>
                    <Select
                        labelId='demo-simple-select-outlined-label'
                        id='demo-simple-select-outlined'
                        value={fields.value}
                        onChange={fields.handler}
                        label={fields.label}
                        error={fields.error}
                        defaultValue={fields.value}
                    >
                        {fields?.list?.map((item) => (
                            <MenuItem key={item.name ? item.name : item} value={item}>
                                {item.name ? item.name : item}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            );
        case 'multiline':
            return (
                <TextField
                    className={classes.input}
                    variant='outlined'
                    type='input'
                    id='standard-basic'
                    label={fields.placeholder}
                    placeholder={fields.placeholder}
                    inputRef={fields.ref}
                    error={fields.error}
                    multiline={true}
                    rows={4}
                    maxRows={4}
                    helperText={fields.helperText}
                    fullWidth
                />
            );
        default:
            return;
    }
};

Input.propTypes = {
    fields: PropTypes.object.isRequired,
    selectWidth: PropTypes.string,
    width: PropTypes.string,
    helperText: PropTypes.string,
};
