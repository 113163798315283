import {
    GET_TASKS_START,
    SELECTED_TASK_START,
    CREATE_TASK_START,
    SHOW_TASK_MODAL_COMPLETE,
    EDIT_TASK_START,
    ADD_SELECTED_TASK,
    DELETE_SELECTED_TASK,
    TASK_FOR_DROPDOWN_START,
    TASK_IS_LOADING,
} from '../actionType';

export const tasksAction = (pageNumber, size) => ({
    type: GET_TASKS_START,
    pageNumber,
    size,
});

export const selectedTaskAction = (taskIds) => ({
    type: SELECTED_TASK_START,
    taskIds,
});

export const createTaskAction = (task) => ({
    type: CREATE_TASK_START,
    task,
});

export const isTaskModalAction = (isTaskModal) => ({
    type: SHOW_TASK_MODAL_COMPLETE,
    isTaskModal,
});

export const editTaskAction = (data) => ({
    type: EDIT_TASK_START,
    data,
});

export const addSelectedTaskAction = (task) => ({
    type: ADD_SELECTED_TASK,
    payload: task,
});

export const delSelectedTaskAction = (id) => ({
    type: DELETE_SELECTED_TASK,
    id,
});

export const taskForDropdownAction = () => ({
    type: TASK_FOR_DROPDOWN_START,
});

export const taskIsLoadingAction = (isLoading) => ({
    type: TASK_IS_LOADING,
    isLoading,
});
