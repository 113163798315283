import { put, call, takeLatest, select } from 'redux-saga/effects';
import XLSX from 'xlsx';

import {
    INVOICE_LIST_START,
    INVOICE_LIST_COMPLETE,
    UPDATED_INVOICE_STATUS_START,
    UPDATED_INVOICE_STATUS_COMPLETE,
    INVOICE_PDF_START,
    INVOICE_PDF_COMPLETE,
    GET_INVOICE_FRO_SHEET,
    SEND_INVOICE_MAIL_START,
} from '../actionType';
import { tokenSelector } from '../selector';
import { apiCall } from '../../utils/apiCall';
import { INVOICES, INVOICEPDF, INVOICE_EMAIL, INVOICE_EXCEL } from '../../utils/urls';
import {
    isLoadingAction,
    snackbarAction,
    isModalSendInvoiceAction,
    isLoadingModalAction,
} from '../actions/utilityActions';
import { dashboardInfoAction } from '../actions/dashboardAction';
import dayjs from 'dayjs';
import { isLoadingForSheet } from '../actions/invoiceAction';

function* getInvoices({ date, pageNumber, size }) {
    yield put(isLoadingAction(true));
    try {
        const token = yield select(tokenSelector);
        const result = yield call(
            apiCall,
            `${INVOICES}/0/${date}/${pageNumber}/${size}`,
            null,
            token,
            'GET'
        );

        yield put({ type: INVOICE_LIST_COMPLETE, result });
    } catch (error) {
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error?.response?.data.message || 'Internal Error!',
            })
        );
    }
    yield put(isLoadingAction(false));
}

function* updateStatus({ data: { id, ...data }, forDashboardOnly }) {
    try {
        const token = yield select(tokenSelector);
        const result = yield call(apiCall, `${INVOICES}/${id}`, data, token, 'PUT');

        if (!forDashboardOnly) yield put({ type: UPDATED_INVOICE_STATUS_COMPLETE, data, id });

        /**
         * Only for dashboard tab
         */
        if (forDashboardOnly) {
            const now = dayjs();
            const today = now.format('YYYY-MM-DD');
            const firstDayOfMonth = now.startOf('month').format('YYYY-MM-DD');
            yield put(dashboardInfoAction(firstDayOfMonth, today));
        }

        yield put(
            snackbarAction({
                open: true,
                type: 'success',
                message: result.data.message,
            })
        );
    } catch (error) {
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error?.response?.data.message || 'Internal Error!',
            })
        );
    }
}

function* getInvoicePDF({ id }) {
    try {
        const responseType = {
            responseType: 'blob',
        };

        const result = yield call(
            apiCall,
            `${INVOICEPDF}/${id}`,
            null,
            null,
            'GET',
            null,
            responseType
        );

        /**
         * Creating blob to render the PDF file
         */
        const blob = yield new Blob([result.data], { type: 'application/pdf' });
        const obj_url = yield URL.createObjectURL(blob);

        yield put({ type: INVOICE_PDF_COMPLETE, obj_url });
    } catch (error) {
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error?.response?.data.message || 'Internal Error',
            })
        );
    }
    yield put(isLoadingAction(false));
}

function* getInvoicesForSheets({ startDate, endDate }) {
    yield put(isLoadingForSheet(true));
    try {
        const now = dayjs();
        const token = yield select(tokenSelector);
        const response = yield call(
            apiCall,
            `${INVOICE_EXCEL}/${startDate}/${endDate}`,
            null,
            token,
            'GET'
        );

        const invoices = yield response.data?.items;

        if (!invoices.length) {
            yield put(isLoadingForSheet(false));

            return yield put(
                snackbarAction({
                    open: true,
                    type: 'error',
                    message: 'Not enough data found!',
                })
            );
        }

        /** Add Header */
        const heading = [
            [
                '#',
                '# Invoice',
                'Customer',
                'Worker',
                'Date',
                'Notes',
                'Qty Vehicle',
                'Value Invoice',
                'Past Due',
                'Status',
            ],
        ];

        /** Create row data */
        let totalPrice = 0;
        let totalVehicle = 0;
        const ws_data = yield invoices?.map((data, index) => {
            totalPrice += data?.total;
            totalVehicle += data?.vehicles?.length;
            return [
                index + 1,
                data?.number,
                data?.customer?.name,
                data?.user?.first_name + ' ' + data?.user?.last_name,
                data?.created_at,
                '',
                data?.vehicles?.length,
                data?.total,
                now.$D - dayjs(data?.updated_at).$D,
                data?.status,
            ];
        });

        /**Add Total */
        ws_data.push(['-', 'Total', ...Array(4), totalVehicle, totalPrice]);

        /** Invoice 2nd */
        const heading2 = [
            [
                '#',
                '# Invoice',
                'Date',
                'Dealer',
                'Worker',
                'YEAR',
                'COLOR',
                'MAKE/MODEL',
                'STOCK',
                'VIN',
                'PACKAGE',
                'TASKS',
                'TOTAL',
                'Status',
            ],
        ];

        let row = 1;
        const ws_data2 = yield invoices?.flatMap((data) => {
            return (
                data?.vehicles?.map((vehicle) => [
                    row++,
                    data?.number,
                    data?.created_at,
                    data?.customer?.name,
                    data?.user?.first_name + ' ' + data?.user?.last_name,
                    vehicle?.year,
                    vehicle?.color,
                    vehicle?.make + ' - ' + vehicle?.model,
                    vehicle?.stock,
                    vehicle?.vin,
                    '',
                    vehicle?.tasks?.map((tsk) => tsk?.name).join(', '),
                    vehicle?.total,
                    data?.status,
                ]) || []
            );
        });

        const generateSheet = (heading, ws_data, filename) => {
            /** Create Workbook */
            const wb = XLSX.utils.book_new();
            wb.Props = {
                Title: 'TRKRBox Invoice sheet',
                Subject: 'invoice',
                Author: 'EricGit.me',
                CreatedDate: now.format('MM-DD-YYYY'),
            };

            /** Create Sheet name */
            wb.SheetNames.push('TRKRBox-invoice');

            /** Add Header */

            XLSX.utils.sheet_add_aoa(wb, heading);

            /** Create sheet from array */
            const ws = XLSX.utils.sheet_add_json(wb, ws_data, { skipHeader: true, origin: 'A2' });

            /** Assign sheet obj to workboot sheet array */
            wb.Sheets['TRKRBox-invoice'] = ws;

            /** Export workbook as xls */
            XLSX.writeFile(wb, filename);
        };

        /** Create xls file */
        yield generateSheet(heading, ws_data, `TRKRBox-${now.format('MM-DD-YYYY')}.xlsx`);
        setTimeout(
            () =>
                generateSheet(
                    heading2,
                    ws_data2,
                    `TRKRBox-vehcile-${now.format('MM-DD-YYYY')}.xlsx`
                ),
            3000
        );

        yield put(isLoadingForSheet(false));
    } catch (error) {
        yield put(isLoadingForSheet(false));

        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error?.response?.data.message || 'Internal Error.',
            })
        );
    }
}

function* sendInvoiceEmail({ infoEmail }) {
    try {
        const token = yield select(tokenSelector);
        const result = yield call(apiCall, `${INVOICE_EMAIL}`, infoEmail, token, 'POST');
        yield put(
            snackbarAction({
                open: true,
                type: 'success',
                message: result?.data?.message,
            })
        );
        yield put(isModalSendInvoiceAction(false));
    } catch (error) {
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error?.response?.data?.message || 'Internal Error!',
            })
        );
    }
    yield put(isLoadingModalAction(false));
}

export default function* watchInvoices() {
    yield takeLatest(INVOICE_LIST_START, getInvoices);
    yield takeLatest(INVOICE_PDF_START, getInvoicePDF);
    yield takeLatest(UPDATED_INVOICE_STATUS_START, updateStatus);
    yield takeLatest(GET_INVOICE_FRO_SHEET, getInvoicesForSheets);
    yield takeLatest(SEND_INVOICE_MAIL_START, sendInvoiceEmail);
}
