import { makeStyles, Paper } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { CountBox } from './components/countBoxes';
import { Line } from './components/LineChart/Line.js';
import { Loading, NoData, SearchBar } from '../../components';
import { CollapsibleTable } from '../Invoices/components/CollapsibleTable/collapseibleTable';
import { SendInvoice } from '../Invoices/components/SendInvoice/sendInvoice';
// Styling
const useStyles = makeStyles(() => ({
    space: {
        margin: '23px 0',
    },
}));
export const View = ({
    startDate,
    endDate,
    startDateHandler,
    endDateHandler,
    onSearchHandler,
    isLoading,
    data,
    onBoxHandler,
    boxData,
    handleInvoiceStatusChange,
    selectedBoxTab,
    updateOpenDateInInvoice,
    handleSendInvoiceOpen,
    handleSendInvoiceClose,
    isModalSendInvoice,
    emailInfoObj,
    isLoadingAction,
    isLoadingModalAction,
    isLoadingModal,
}) => {
    const classes = useStyles();

    return (
        <>
            <Helmet title='Dashboard - TRKRBox' />

            <SearchBar
                startDate={startDate}
                endDate={endDate}
                startDateHandler={startDateHandler}
                endDateHandler={endDateHandler}
                onSearchHandler={onSearchHandler}
                isLoading={isLoading}
                btnTitle='Search'
            />
            <div className={classes.space} />
            <CountBox onBoxHandler={onBoxHandler} data={boxData} selectedBoxTab={selectedBoxTab} />
            <div className={classes.space} />
            {/* <Line /> */}
            <SendInvoice
                isModal={isModalSendInvoice}
                handleClose={handleSendInvoiceClose}
                isLoading={isLoadingModal}
                InfoEmail={emailInfoObj}
                isLoadingAction={isLoadingModalAction}
            />
            <Paper>
                <Loading isLoading={isLoading} />
                <CollapsibleTable
                    isLoading={isLoading}
                    invoicesList={data?.invoices}
                    handleOnChange={handleInvoiceStatusChange}
                    updateOpenDateInInvoice={updateOpenDateInInvoice}
                    handleSendInvoiceOpen={handleSendInvoiceOpen}
                />
                <NoData data={!!data?.invoices?.length} isLoading={isLoading} />
            </Paper>
        </>
    );
};

View.proptTypes = {
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    startDateHandler: PropTypes.func,
    endDateHandler: PropTypes.func,
    onSearchHandler: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    data: PropTypes.array,
    boxData: PropTypes.array,
    onBoxHandler: PropTypes.func,
    handleInvoiceStatusChange: PropTypes.func,
    selectedBoxTab: PropTypes.string.isRequired,
    updateOpenDateInInvoice: PropTypes.func.isRequired,
};
