import { get } from 'lodash';
import {
    INVOICE_LIST_COMPLETE,
    INVOICE_PDF_COMPLETE,
    UPDATED_INVOICE_STATUS_COMPLETE,
    IS_LOADING_FOR_SHEET,
} from '../actionType';

const initialState = {
    invoicesList: [],
    invoiceById: null,
    isLoadingForSheet: false,
};

export const invoiceReducer = (state = initialState, action) => {
    switch (action.type) {
        case INVOICE_LIST_COMPLETE:
            return {
                ...state,
                invoicesList: get(action, 'result.data'),
            };

        case INVOICE_PDF_COMPLETE:
            return {
                ...state,
                invoiceById: get(action, 'obj_url'),
            };

        case UPDATED_INVOICE_STATUS_COMPLETE:
            const index = state.invoicesList.items.findIndex(
                (invoice) => invoice._id === action.id
            );

            const copyAnInvoice = {
                ...state.invoicesList.items[index],
                ...action.data,
            };

            const copyAllInvoices = [].concat(
                state.invoicesList.items.slice(0, index),
                copyAnInvoice,
                state.invoicesList.items.slice(index + 1)
            );

            return {
                ...state,
                invoicesList: {
                    ...state.invoicesList,
                    items: copyAllInvoices,
                },
            };

        case IS_LOADING_FOR_SHEET:
            return {
                ...state,
                isLoadingForSheet: get(action, 'isLoading'),
            };
        default:
            return state;
    }
};
