import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';

const useStyles = makeStyles((theme) => ({
    dialog: {
        borderRadius: ({ borderRadius }) => borderRadius,
    },
}));

export const Modal = ({ open, handleClose, children, borderRadius }) => {
    const classes = useStyles({ borderRadius });
    return (
        <Dialog
            classes={{ paper: classes.dialog }}
            onClose={handleClose}
            aria-labelledby='simple-dialog-title'
            open={open}
        >
            {children}
        </Dialog>
    );
};

Modal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    children: PropTypes.element.isRequired,
    borderRadius: PropTypes.number,
};
