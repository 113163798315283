import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Button, Input, Modal } from '../../../../components/';

import { createUserAction } from '../../../../Redux/actions/userListActions';

// Styling

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px 20px',
    },
    bold: {
        fontWeight: 600,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    icon: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: 20,
        cursor: 'pointer',
        fontSize: 18,
    },
}));

export const CreateUser = ({ isModal, handleClose, isLoading, isLoadingAction }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const [selectValue, setSelectValue] = useState('Employee');
    const firstNameRef = useRef(null);
    const lastNameRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);
    const passwordRef = useRef(null);
    const confirmPasswordRef = useRef(null);

    const [firstError, setFirstError] = useState(false);
    const [lastError, setLastError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [passwordError, setPasswordError] = useState(false);
    const [cPasswordError, setCPasswordError] = useState(false);
    const [selectError, setSelectError] = useState(false);

    const setSelectValueHandler = (e) => {
        setSelectValue(e.target.value);
    };

    const inputFields = {
        firstName: {
            elementType: 'input',
            placeholder: 'First name',
            ref: firstNameRef,
            error: firstError,
        },
        lastName: {
            elementType: 'input',
            placeholder: 'Last name',
            ref: lastNameRef,
            error: lastError,
        },
        email: {
            elementType: 'email',
            placeholder: 'E-mail',
            ref: emailRef,
            error: emailError,
        },
        phone: {
            elementType: 'phone',
            placeholder: 'Phone Number',
            ref: phoneRef,
            error: phoneError,
        },
        password: {
            elementType: 'password',
            placeholder: 'Password',
            ref: passwordRef,
            error: passwordError,
        },
        confirmPassword: {
            elementType: 'password',
            placeholder: 'Confirm Password',
            ref: confirmPasswordRef,
            error: cPasswordError,
        },
        userType: {
            label: 'User Type',
            elementType: 'select',
            value: selectValue,
            list: ['Admin', 'Employee'],
            error: selectError,
            handler: setSelectValueHandler,
        },
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const first = firstNameRef?.current?.value || setFirstError(true);
        const last = lastNameRef?.current?.value || setLastError(true);
        const email = emailRef?.current?.value || setEmailError(true);
        const phone = phoneRef?.current?.value || setPhoneError(true);
        const password = passwordRef?.current?.value || setPasswordError(true);
        const confirmPassword = confirmPasswordRef?.current?.value || setCPasswordError(true);
        selectValue || setSelectError(true);

        if (password !== confirmPassword) {
            setPasswordError(true);
            setCPasswordError(true);
        }

        /** if it has value, false the error */
        first && setFirstError(false);
        last && setLastError(false);
        email && setEmailError(false);
        phone && setPhoneError(false);
        selectValue && setSelectError(false);

        if (password === confirmPassword) {
            setPasswordError(false);
            setCPasswordError(false);
        }

        /** stop executing  */
        if (
            !first ||
            !last ||
            !email ||
            !phone ||
            !password ||
            !confirmPassword ||
            !selectValue ||
            password !== confirmPassword
        )
            return;

        dispatch(isLoadingAction(true));

        dispatch(
            createUserAction({
                first_name: first,
                last_name: last,
                email,
                phone_number: phone,
                organization: 'TRKRBox',
                password,
                type: selectValue,
                status: 'Active',
            })
        );
    };

    return (
        <Modal open={isModal} handleClose={handleClose} borderRadius={10}>
            <Box className={classes.container}>
                <Box className={classes.header}>
                    <Typography className={classes.bold} variant='h6' gutterBottom>
                        Add New User
                    </Typography>
                    <CloseIcon className={classes.icon} onClick={handleClose} />
                </Box>

                <form
                    className={classes.form}
                    noValidate
                    autoComplete='off'
                    onSubmit={handleSubmit}
                >
                    <Grid container>
                        {Object.keys(inputFields).map((key) => (
                            <Grid item xs={6} key={key}>
                                <Input fields={inputFields[key]} />
                            </Grid>
                        ))}
                    </Grid>

                    <Button
                        title='Add'
                        width='100%'
                        marginBottom='0'
                        marginTop='30px'
                        isLoading={isLoading}
                    />
                </form>
            </Box>
        </Modal>
    );
};

CreateUser.prototype = {
    isModal: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoadingAction: PropTypes.func.isRequired,
};
