import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '250px',
    },
});

export const NoData = ({ data, isLoading }) => {
    const classes = useStyles();

    if (data || isLoading) return false;

    return (
        <div className={classes.root}>
            <Typography variant='h5' component='h2' gutterBottom>
                NOT_ENOUGHT_DATA_FOUND
            </Typography>
        </div>
    );
};

NoData.propTypes = {
    data: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired,
};
