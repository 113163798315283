import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';

import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(6),
    },
    table: {
        // maxWidth: 900,
    },
    bold: { fontWeight: 600 },

    cell: {
        padding: '10px',
    },
    arrowDownIcon: {
        display: 'none',
    },
    editIcon: {
        cursor: 'pointer',
    },
}));

export const TableUsers = ({ users, handleEditUser, isLoading }) => {
    const classes = useStyles();

    if (!users?.length || isLoading) return false;

    return (
        <TableContainer className={classes.root}>
            <Table className={classes.table} stickyHeader aria-label='sticky table'>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.bold}>Name</TableCell>
                        <TableCell className={classes.bold} align='left'>
                            Address
                        </TableCell>
                        <TableCell className={classes.bold} align='left'>
                            Website
                        </TableCell>
                        <TableCell className={classes.bold} align='left'>
                            Phone Number
                        </TableCell>
                        <TableCell className={classes.bold} align='left'>
                            Contact
                        </TableCell>
                        <TableCell className={classes.bold} align='left'>
                            Contact Number
                        </TableCell>
                        <TableCell className={classes.bold} align='left'>
                            Contact E-mail
                        </TableCell>
                        <TableCell className={classes.bold} align='right'>
                            Action
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow key={user._id} hover classes={{ root: classes.cell }}>
                            <TableCell component='th' scope='row'>
                                {user?.name}
                            </TableCell>
                            <TableCell classes={{ root: classes.cell }} align='left'>
                                {user?.address}
                            </TableCell>
                            <TableCell classes={{ root: classes.cell }} align='left'>
                                {user?.website}
                            </TableCell>
                            <TableCell classes={{ root: classes.cell }} align='left'>
                                {user?.phone}
                            </TableCell>
                            <TableCell classes={{ root: classes.cell }} align='left'>
                                {user?.contact}
                            </TableCell>
                            <TableCell classes={{ root: classes.cell }} align='left'>
                                {user?.contact_number}
                            </TableCell>
                            <TableCell classes={{ root: classes.cell }} align='left'>
                                {user?.contact_email}
                            </TableCell>

                            <TableCell classes={{ root: classes.cell }} align='right'>
                                <EditIcon
                                    onClick={handleEditUser.bind(this, user)}
                                    className={classes.editIcon}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

TableUsers.propTypes = {
    users: PropTypes.array.isRequired,
    handleEditUser: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
};
