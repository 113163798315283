import { Typography, Grid, Paper, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import { PackageList } from '../PackageList/PackageList';
import { Filter, Loading, Pagination } from '../../../../components/';
import { NoData } from '../../../../components/NoData/noData';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '15px 20px',
    },
}));

export const Packages = ({
    packageList,
    handleModalTaskOpen,
    handleUpdatePackage,
    handleAddNewPackage,
    handlePagination,
    isLoading,
    handleSearch,
    searchValue,
}) => {
    const classes = useStyles();

    return (
        <Grid item xs={7}>
            <Paper className={classes.paper}>
                <Typography variant='h6' gutterBottom>
                    Packages
                </Typography>
                <Filter
                    btnTitle='Add Package'
                    btnWidth='170px'
                    openModalHandler={handleAddNewPackage}
                    onChange={handleSearch}
                    value={searchValue}
                />

                <Loading isLoading={isLoading} />

                <NoData data={!!packageList?.items?.length} isLoading={isLoading} />

                <PackageList
                    isLoading={isLoading}
                    packageList={packageList?.items}
                    handleModalTaskOpen={handleModalTaskOpen}
                    handleUpdatePackage={handleUpdatePackage}
                    handlePagination={handlePagination}
                />

                <Pagination count={packageList?.total_pages} onChange={handlePagination} />
            </Paper>
        </Grid>
    );
};

Packages.propTypes = {
    handleModalTaskOpen: PropTypes.func.isRequired,
    packageList: PropTypes.array.isRequired,
    handleUpdatePackage: PropTypes.func.isRequired,
    handleAddNewPackage: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    handlePagination: PropTypes.func.isRequired,
    handleSearch: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
};
