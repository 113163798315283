import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';

import { userStatusAction } from '../../../../Redux/actions/userListActions';
import { Select } from '../../../../components/';

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: theme.spacing(6),
        overflowX: 'auto',
        marginLeft: 'auto',
        marginRight: 'auto',
        maxHeight: '67vh',
    },
    table: {},
    bold: { fontWeight: 600 },

    cell: {
        padding: '10px',
    },
    arrowDownIcon: {
        display: 'none',
    },
    editIcon: {
        cursor: 'pointer',
    },
}));

export const TableUsers = ({ users, handleEditUser, isLoading }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    if (isLoading || !users?.length) return false;

    const selectData = ['Pending', 'Active', 'Rejected', 'Deactivated'];

    const handleOnChange = (id, e) => {
        dispatch(userStatusAction(id, e.target.value));
    };

    return (
        <TableContainer className={classes.root}>
            <Table className={classes.table} stickyHeader aria-label='sticky table'>
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.bold}>First Name</TableCell>
                        <TableCell className={classes.bold} align='left'>
                            Last Name
                        </TableCell>
                        <TableCell className={classes.bold} align='left'>
                            Phone Number
                        </TableCell>
                        <TableCell className={classes.bold} align='left'>
                            E-mail
                        </TableCell>
                        <TableCell className={classes.bold} align='left'>
                            User Status
                        </TableCell>
                        <TableCell className={classes.bold} align='right'>
                            Action
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user) => (
                        <TableRow key={user._id} hover classes={{ root: classes.cell }}>
                            <TableCell component='th' scope='row'>
                                {user.first_name}
                            </TableCell>
                            <TableCell classes={{ root: classes.cell }} align='left'>
                                {user.last_name}
                            </TableCell>
                            <TableCell classes={{ root: classes.cell }} align='left'>
                                {user.phone_number}
                            </TableCell>
                            <TableCell classes={{ root: classes.cell }} align='left'>
                                {user.email}
                            </TableCell>
                            <TableCell classes={{ root: classes.cell }} align='left'>
                                <Select
                                    data={selectData}
                                    selectedItem={user.status}
                                    handleOnChange={(e) => handleOnChange(user._id, e)}
                                    colors={{
                                        green: 'Active',
                                        yellow: 'Pending',
                                        red: 'Rejected',
                                        normal: 'Deactivated',
                                    }}
                                />
                            </TableCell>
                            <TableCell classes={{ root: classes.cell }} align='right'>
                                <EditIcon
                                    onClick={handleEditUser.bind(this, user)}
                                    className={classes.editIcon}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

TableUsers.propTypes = {
    isLoading: PropTypes.bool.isRequired,
    users: PropTypes.array,
    handleEditUser: PropTypes.func.isRequired,
};
