import { createMuiTheme } from '@material-ui/core/styles';

export const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#FE0202',
        },
        secondary: {
            main: '#3A3A3A',
        },
        white: '#fff',
        green: '#1FBE3F',
        bgColor: '#E5EBF1',
        gray: '#E6E6E6',
        active: {
            main: '#3ABF5D',
            secondary: '#D5FFE6',
        },
        rejected: {
            main: '#FF5206',
            secondary: '#FFE0D5',
        },
        pending: {
            main: '#F9BF1F',
            secondary: '#FFFCD5',
        },
    },
    typography: {
        fontFamily: ['Helveticaneue', 'MyriadPro', 'sans-serif'].join(', '),
    },
    overrides: {
        MuiPaper: {
            rounded: {
                borderRadius: 0,
            },
        },
    },
});
