import { combineReducers } from 'redux';

import { utilityReducer } from './utilityReducer';
import { loginReducer } from './loginReducer';
import { userListReducer } from './userListReducer';
import { forgotPasswordReducer } from './forgotPassReducer';
import { userReducer } from './userReducer';
import { customerReducer } from './customerReducer';
import { invoiceReducer } from './invoiceReducer';
import { packageReducer } from './packageReducer';
import { taskReducer } from './taskReducer';
import { dashboardRedux } from './dashboardRedux';

export default combineReducers({
    utilityReducer,
    loginReducer,
    userListReducer,
    forgotPasswordReducer,
    userReducer,
    customerReducer,
    invoiceReducer,
    packageReducer,
    taskReducer,
    dashboardRedux,
});
