import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SvgIcon from '@material-ui/core/SvgIcon';

// Styling

const useStyles = makeStyles({
    root: {
        width: '2em',
    },
});

export const Navbar = ({ listItem }) => {
    const classes = useStyles();

    return (
        <List>
            {listItem.map((item) => (
                <ListItem button key={item.name} component={Link} to={item.to}>
                    <ListItemIcon>
                        <SvgIcon classes={{ root: classes.root }} component={item.icon} />
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                </ListItem>
            ))}
        </List>
    );
};

Navbar.propTypes = {
    listItem: PropTypes.array.isRequired,
};
