import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet';

import { invoiceByIdSelector, isLoadingSelector } from '../../Redux/selector';
import { Loading } from '../../components';
import { invoicePDFAction } from '../../Redux/actions/invoiceAction';
import { isLoadingAction } from '../../Redux/actions/utilityActions';

export const InvoicePDF = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const isLoading = useSelector((state) => isLoadingSelector(state));
    const invoiceInfo = useSelector((state) => invoiceByIdSelector(state));

    /**
     * 
       this is URL "/generate-invoice/60dd39c169e7dddcf86770a5"
     */
    const path = history?.location?.pathname;

    useEffect(() => {
        /**
         * Split it to ["", "generate-invoice", "60dd39c169e7dddcf86770a5"]
         */
        const id = path.split('/')[2];

        dispatch(isLoadingAction(true));
        dispatch(invoicePDFAction(id));
    }, []);

    return (
        <>
            <Helmet title='Generate Invoice PDF - TRKRBox' />

            <Loading isLoading={isLoading} />
            {invoiceInfo && <iframe src={invoiceInfo} style={{ width: '100%', height: '100vh' }} />}
        </>
    );
};
