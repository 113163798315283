import { useState } from 'react';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { SearchBar } from '../../components/';

export const View = ({
    startDate,
    endDate,
    startDateHandler,
    endDateHandler,
    onExportSheetHandler,
    isLoading,
}) => {
    return (
        <SearchBar
            startDate={startDate}
            endDate={endDate}
            startDateHandler={startDateHandler}
            endDateHandler={endDateHandler}
            onSearchHandler={onExportSheetHandler}
            isLoading={isLoading}
            btnTitle='Export Sheets'
        />
    );
};

View.propTypes = {
    startDate: PropTypes.any.isRequired,
    endDate: PropTypes.any.isRequired,
    startDateHandler: PropTypes.any.isRequired,
    endDateHandler: PropTypes.any.isRequired,
    onExportSheetHandler: PropTypes.any.isRequired,
    isLoading: PropTypes.any.isRequired,
};
