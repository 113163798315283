import { Typography, Link as LinkMaterial } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { Form } from '../../components/';
import { ReactComponent as Logo } from '../../assets/images/new_logo.svg';

// Styles
const useStyles = makeStyles((theme) => ({
    container: {
        margin: '45px auto 35px',
        textAlign: 'center',
    },
    logo: {
        width: '200px',
    },

    forgot: {
        textAlign: 'center',
    },
}));

export const View = ({ inputFields, handleSubmit }) => {
    const classes = useStyles();

    const renderFormHeader = (
        <>
            <div className={classes.container}>
                <Logo className={classes.logo} />
            </div>
        </>
    );

    const renderFormBottom = (
        <>
            <Typography className={classes.forgot}>
                <LinkMaterial component={Link} to='/forgot-password'>
                    Forgot Password?
                </LinkMaterial>
            </Typography>
        </>
    );

    return (
        <>
            <Helmet title='TRKRBOX Login' />
            <Form
                inputFields={inputFields}
                buttonTitle='Log In'
                formHeader={renderFormHeader}
                formBottom={renderFormBottom}
                formHeight={400}
                handleSubmit={handleSubmit}
            />
        </>
    );
};

View.propTypes = {
    inputFields: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};
