import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import Pagination from '@material-ui/lab/Pagination';
import PaginationItem from '@material-ui/lab/PaginationItem';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '15px 20px',
    },
    pagination: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '20px 0 0',
    },
    selected: {
        backgroundColor: theme.palette.primary.main + '!important',
        color: theme.palette.common.white,
    },
}));

export const PaginationComp = ({ count, onChange }) => {
    const classes = useStyles();

    return (
        <Box className={classes.pagination}>
            <Pagination
                count={count}
                onChange={onChange}
                renderItem={(item) => (
                    <PaginationItem {...item} classes={{ selected: classes.selected }} />
                )}
            />
        </Box>
    );
};

PaginationComp.propTypes = {
    count: PropTypes.number,
    handlePagination: PropTypes.func,
};
