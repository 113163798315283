import { get } from 'lodash';
import {
    SELECTED_TASK_COMPLETE,
    GET_TASKS_COMPLETE,
    SHOW_TASK_MODAL_COMPLETE,
    EDIT_TASK_COMPLETE,
    ADD_SELECTED_TASK,
    DELETE_SELECTED_TASK,
    TASK_FOR_DROPDOWN_COMPLETE,
    TASK_IS_LOADING,
} from '../actionType';

const initialState = {
    tasks: [],
    selectedTasks: [],
    isTaskModal: false,
    taskForDropDown: [],
    isLoading: false,
};

export const taskReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_TASKS_COMPLETE:
            return {
                ...state,
                tasks: get(action, 'result.data'),
            };
        case EDIT_TASK_COMPLETE:
            const index = state.tasks.items.findIndex((tsk) => tsk._id === action.data._id);

            const copyATask = {
                ...state.tasks.items[index],
                ...action.data,
            };

            const copyAllTasks = [].concat(
                state.tasks.items.slice(0, index),
                copyATask,
                state.tasks.items.slice(index + 1)
            );
            return {
                ...state,
                tasks: {
                    ...state.tasks,
                    items: copyAllTasks,
                },
            };

        case SELECTED_TASK_COMPLETE:
            return {
                ...state,
                selectedTasks: get(action, 'result'),
            };

        case SHOW_TASK_MODAL_COMPLETE:
            return {
                ...state,
                isTaskModal: get(action, 'isTaskModal'),
            };

        case ADD_SELECTED_TASK:
            return {
                ...state,
                selectedTasks: [get(action, 'payload'), ...state.selectedTasks],
            };

        case DELETE_SELECTED_TASK:
            return {
                ...state,
                selectedTasks: state.selectedTasks.filter((tsk) => tsk._id !== action.id),
            };

        case TASK_FOR_DROPDOWN_COMPLETE:
            return {
                ...state,
                taskForDropDown: get(action, 'result.data'),
            };

        case TASK_IS_LOADING:
            return {
                ...state,
                isLoading: get(action, 'isLoading'),
            };

        default:
            return state;
    }
};
