import { put, call, select, takeLatest } from 'redux-saga/effects';

import { snackbarAction } from '../actions/utilityActions';
import { DASHBOARD_DATA_START, DASHBOARD_DATA_COMPLETE } from '../actionType';
import { tokenSelector } from '../selector';
import { apiCall } from '../../utils/apiCall';
import { DASHBOARD_URL } from '../../utils/urls';
import { isLoadingAction } from '../actions/utilityActions';

function* getDashboardInfo({ startDate, endDate }) {
    yield put(isLoadingAction(true));
    try {
        const token = yield select(tokenSelector);
        const result = yield call(
            apiCall,
            `${DASHBOARD_URL}/0/${startDate}/${endDate}`,
            null,
            token,
            'GET'
        );

        yield put({ type: DASHBOARD_DATA_COMPLETE, result });
    } catch (error) {
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error?.response?.data.message || 'Internal Error!',
            })
        );
    }

    yield put(isLoadingAction(false));
}

export default function* watchDashboard() {
    yield takeLatest(DASHBOARD_DATA_START, getDashboardInfo);
}
