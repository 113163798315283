import { get } from 'lodash';
import { USER_LIST_COMPLETE, USER_EDIT_COMPLETE, CREATE_NEW_USER_COMPLETE } from '../actionType';

const initialState = {
    userList: [],
};

export const userListReducer = (state = initialState, action) => {
    switch (action.type) {
        case USER_LIST_COMPLETE:
            return {
                ...state,
                userList: get(action, 'result.data'),
            };

        case USER_EDIT_COMPLETE:
            const index = state.userList.items.findIndex(({ _id }) => _id === action.id);

            const updatedUserData = {
                ...state.userList.items[index],
                ...action.data,
            };

            const copyUserList = [].concat(
                state.userList.items.slice(0, index),
                updatedUserData,
                state.userList.items.slice(index + 1)
            );

            return {
                ...state,
                userList: {
                    ...state.userList,
                    items: copyUserList,
                },
            };

        case CREATE_NEW_USER_COMPLETE:
            return {
                ...state,
                userList: {
                    ...state.userList,
                    items: [...state.userList.items, action.data],
                },
            };
        default:
            return state;
    }
};
