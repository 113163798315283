import { put, call, takeLatest, select } from 'redux-saga/effects';
import { apiCall } from '../../utils/apiCall';
import { CUSTOMERS } from '../../utils/urls';
import {
    CREATE_NEW_CUSTOMER_START,
    CREATE_NEW_CUSTOMER_COMPLETE,
    CUSTOMER_EDIT_START,
    CUSTOMER_EDIT_COMPLETE,
    CUSTOMER_LIST_START,
    CUSTOMER_LIST_COMPLETE,
    IS_MODAL_CREATE_USER,
} from '../actionType';
import { tokenSelector } from '../selector/';
import { isLoadingAction, snackbarAction, isModalEditUserAction } from '../actions/utilityActions';

function* allCustomerList({ pageNumber, size }) {
    try {
        const token = yield select(tokenSelector);
        const result = yield call(
            apiCall,
            `${CUSTOMERS}/${pageNumber}/${size}`,
            null,
            token,
            'GET'
        );

        yield put({ type: CUSTOMER_LIST_COMPLETE, result });
    } catch (error) {
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error?.data?.msg,
            })
        );
    }
    yield put(isLoadingAction(false));
}

function* updateCustomer({ id, data }) {
    try {
        const token = yield select(tokenSelector);
        const result = yield call(apiCall, `${CUSTOMERS}/${id}`, data, token, 'PUT');

        yield put({ type: CUSTOMER_EDIT_COMPLETE, id, data });

        yield put(
            snackbarAction({
                open: true,
                type: 'success',
                message: result?.data?.message,
            })
        );
    } catch (error) {
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error?.data?.msg,
            })
        );
    }

    yield put(isLoadingAction(false));
    yield put(isModalEditUserAction(false));
}

function* createCustomer({ data }) {
    try {
        const token = yield select(tokenSelector);
        const result = yield call(apiCall, CUSTOMERS, data, token, 'POST');

        yield put(
            snackbarAction({
                open: true,
                type: 'success',
                message: result?.data?.message,
            })
        );

        yield put({
            type: CREATE_NEW_CUSTOMER_COMPLETE,
            data: { _id: Math.random() * 9999999, ...data },
        });

        yield put({ type: IS_MODAL_CREATE_USER, isModal: false });
        yield put({ type: CUSTOMER_LIST_START, pageNumber: 1, size: 10 });
    } catch (error) {
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error?.response?.data?.message || 'Internal Error!',
            })
        );
    }
    yield put(isLoadingAction(false));
}

export default function* watchUserList() {
    yield takeLatest(CUSTOMER_LIST_START, allCustomerList);
    yield takeLatest(CUSTOMER_EDIT_START, updateCustomer);
    yield takeLatest(CREATE_NEW_CUSTOMER_START, createCustomer);
}
