import { get } from 'lodash';
import { DASHBOARD_DATA_COMPLETE } from '../actionType';

const initialState = {
    dashboardInfo: [],
};

export const dashboardRedux = (state = initialState, action) => {
    switch (action.type) {
        case DASHBOARD_DATA_COMPLETE:
            return {
                ...state,
                dashboardInfo: get(action, 'result.data'),
            };
        default:
            return state;
    }
};
