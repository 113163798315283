/** Utility  */
export const SNACBAR = 'SNACKBAR';
export const IS_LOADING = 'IS_LOADING';
export const IS_MODAL_CREATE_USER = 'IS_MODAL_CREATE_USER';
export const IS_MODAL_EDIT_USER = 'IS_MODAL_EDIT_USER';
export const IS_MODAL_SEND_INVOICE = 'IS_MODAL_SEND_INVOICE';
export const IS_LOADING_MODAL = 'IS_LOADING_MODAL';
/** Login */
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_COMPLETE = 'LOGIN_COMPLETE';
export const ASSESS_TOKEN = 'ASSESS_TOKEN';
export const EMAIL = 'EMAIL';
export const IS_RESET_TOKEN_VALID = 'IS_RESET_TOKEN_VALID';
export const IS_RESET_TOKEN_LOADING = 'IS_RESET_TOKEN_LOADING';

/** Logout  */
export const LOGOUT_START = 'LOG_OUT';
export const LOGOUT_COMPLETE = 'LOG_OUT';

/** Dashboard */
export const DASHBOARD_DATA_START = 'DASHBOARD_DATA_START';
export const DASHBOARD_DATA_COMPLETE = 'DASHBOARD_DATA_COMPLETE';

/** USER LIST */
export const USER_LIST_START = 'USER_LIST_START';
export const USER_LIST_COMPLETE = 'USER_LIST_COMPLETE';
export const USER_EDIT_START = 'USER_EDIT_START';
export const USER_EDIT_COMPLETE = 'USER_EDIT_COMPLETE';
export const CREATE_NEW_USER_START = 'CREATE_NEW_USER_START';
export const CREATE_NEW_USER_COMPLETE = 'CREATE_NEW_USER_COMPLETE';
export const EDIT_USER_START = 'EDIT_USER_START';
export const EDIT_USER_COMPLETE = 'EDIT_USER_COMPLETE';

/** Forgot password */
export const FORGOT_PASS_START = 'FORGOT_PASS_START';
export const FORGOT_PASS_SAVE = 'FORGOT_PASS_SAVE';
export const SHOW_FORGOT_MODAL = 'SHOW_FORGOT_MODAL';

/** User Info  */
export const USER_INFO_START = 'USER_INFO_START';
export const USER_INFO_COMPLETE = 'USER_INFO_COMPLETE';

/** Customer */
export const CUSTOMER_LIST_START = 'CUSTOMER_LIST_START';
export const CUSTOMER_LIST_COMPLETE = 'CUSTOMER_LIST_COMPLETE';
export const CUSTOMER_EDIT_START = 'CUSTOMER_EDIT_START';
export const CUSTOMER_EDIT_COMPLETE = 'CUSTOMER_EDIT_COMPLETE';
export const CREATE_NEW_CUSTOMER_START = 'CREATE_NEW_CUSTOMER_START';
export const CREATE_NEW_CUSTOMER_COMPLETE = 'CREATE_NEW_CUSTOMER_COMPLETE';

/** Invoice  */
export const INVOICE_LIST_START = 'INVOICE_LIST_START';
export const INVOICE_LIST_COMPLETE = 'INVOICE_LIST_COMPLETE';
export const INVOICE_PDF_START = 'INVOICE_PDF_START';
export const INVOICE_PDF_COMPLETE = 'INVOICE_PDF_COMPLETE';
export const UPDATED_INVOICE_STATUS_START = 'UPDATED_INVOICE_STATUS_START';
export const UPDATED_INVOICE_STATUS_COMPLETE = 'UPDATED_INVOICE_STATUS_COMPLETE';
export const GET_INVOICE_FRO_SHEET = 'GET_INVOICE_FRO_SHEET';
export const IS_LOADING_FOR_SHEET = 'IS_LOADING_FOR_SHEET';
export const SEND_INVOICE_MAIL_START = 'SEND_INVOICE_MAIL_START';
export const SEND_INVOICE_MAIL_COMPLETE = 'SEND_INVOICE_MAIL_COMPLETE';
/** Package */
export const PACKAGE_LIST_START = 'PACKAGE_LIST_START';
export const PACKAGE_LIST_COMPLETE = 'PACKAGE_LIST_COMPLETE';
export const CREATE_PACKAGE = 'CREATE_PACKAGE';
export const EDIT_PACKAGE_START = 'EDIT_PACKAGE_START';
export const EDIT_PACKAGE_COMPLETE = 'EDIT_PACKAGE_COMPLETE';
export const PACKAGE_IS_LOADING = 'PACKAGE_IS_LOADING';

/** Tasks */
export const GET_TASKS_START = 'GET_TASKS_START';
export const GET_TASKS_COMPLETE = 'GET_TASKS_COMPLETE';
export const SELECTED_TASK_START = 'SELECTED_TASK_START';
export const SELECTED_TASK_COMPLETE = 'SELECTED_TASK_COMPLETE';
export const CREATE_TASK_START = 'CREATE_TASK_START';
export const CREATE_TASK_COMPLETE = 'CREATE_TASK_COMPLETE';
export const SHOW_TASK_MODAL_COMPLETE = 'SHOW_TASK_MODAL_COMPLETE';
export const EDIT_TASK_START = 'EDIT_TASK_START';
export const EDIT_TASK_COMPLETE = 'EDIT_TASK_COMPLETE';
export const ADD_SELECTED_TASK = 'ADD_SELECTED_TASK';
export const DELETE_SELECTED_TASK = 'DELETE_SELECTED_TASK';
export const TASK_FOR_DROPDOWN_START = 'TASK_FOR_DROPDOWN_START';
export const TASK_FOR_DROPDOWN_COMPLETE = 'TASK_FOR_DROPDOWN_COMPLETE';
export const TASK_IS_LOADING = 'TASK_IS_LOADING';

/** Search */
export const SEARCH_QUERY_START = 'SEARCH_QUERY_START';
