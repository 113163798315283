import { get } from 'lodash';

import { EDIT_PACKAGE_COMPLETE, PACKAGE_LIST_COMPLETE, PACKAGE_IS_LOADING } from '../actionType';

const initialState = {
    packageList: [],
    isLoading: false,
};

export const packageReducer = (state = initialState, action) => {
    switch (action.type) {
        case PACKAGE_LIST_COMPLETE:
            return {
                ...state,
                packageList: get(action, 'result.data'),
            };

        case EDIT_PACKAGE_COMPLETE:
            const index = state.packageList.items.findIndex((pkg) => pkg._id === action.data._id);

            const copyAPackage = {
                ...state.packageList.items[index],
                ...action.data,
            };

            const updateAllPackages = [].concat(
                state.packageList.items.slice(0, index),
                copyAPackage,
                state.packageList.items.slice(index + 1)
            );

            return {
                ...state,
                packageList: {
                    ...state.packageList,
                    items: updateAllPackages,
                },
            };

        case PACKAGE_IS_LOADING:
            return {
                ...state,
                isLoading: get(action, 'isLoading'),
            };

        default:
            return state;
    }
};
