import { call, select, put, takeLatest } from 'redux-saga/effects';

import { isLoadingAction, snackbarAction } from '../actions/utilityActions';
import { packageIsLoadingAction } from '../actions/packageAction';
import { SEARCH_QUERY_START, TASK_IS_LOADING } from '../actionType';
import { packageIsLoadingSelector, taskIsLoadingSelector, tokenSelector } from '../selector';
import { apiCall } from '../../utils/apiCall';

function* searchQuery({ url, query, actionForRedux }) {
    try {
        const token = yield select(tokenSelector);
        const result = yield call(apiCall, `${url}/${query}/1/10`, null, token, 'GET');

        if (!result.data.items)
            yield put(
                snackbarAction({
                    open: true,
                    type: 'warning',
                    message: result.data.message,
                })
            );

        yield put({ type: actionForRedux, result });
    } catch (error) {
        console.log('Search Error =>', error, error.message);
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error.response?.data.message || 'Internal Error!',
            })
        );
    }
    const packageIsLoading = yield select(packageIsLoadingSelector);
    const taskIsLoading = yield select(taskIsLoadingSelector);

    yield put(isLoadingAction(false));
    if (packageIsLoading) yield put(packageIsLoadingAction(false));
    if (taskIsLoading) yield put({ type: TASK_IS_LOADING, isLoading: false });
}

export default function* watchSearch() {
    yield takeLatest(SEARCH_QUERY_START, searchQuery);
}
