import { put } from 'redux-saga/effects';

import { LOGIN_COMPLETE } from '../actionType';
import { getLocalStorage, REFRESH_TOKEN, ORGANIZATION, EMAIL } from '../../utils/localStorage';

export default function* storeToken() {
    const token = yield getLocalStorage();
    const refresh = yield getLocalStorage(REFRESH_TOKEN);
    const org = yield getLocalStorage(ORGANIZATION);
    const email = yield getLocalStorage(EMAIL);

    const payload = {
        access_token: token,
        refresh_token: refresh,
        organization: org,
        email,
    };

    yield put({ type: LOGIN_COMPLETE, payload });
}
