import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { packageIsLoadingAction, packageListAction } from '../../Redux/actions/packageAction';
import { searchAction } from '../../Redux/actions/searchAction';
import {
    isTaskModalAction,
    selectedTaskAction,
    taskForDropdownAction,
    taskIsLoadingAction,
    tasksAction,
} from '../../Redux/actions/taskAction';
import { SELECTED_TASK_COMPLETE } from '../../Redux/actionType';

import {
    isLoadingSelector,
    isTaskModalSelector,
    packageIsLoadingSelector,
    packageListSelector,
    selectedTaskSelector,
    taskForDropdownSelector,
    taskIsLoadingSelector,
    tasksSelector,
} from '../../Redux/selector/';
import { PACKAGE_SEARCH, TASK_SEARCH } from '../../utils/urls';
import { PACKAGE_LIST_COMPLETE, GET_TASKS_COMPLETE } from '../../Redux/actionType';

import { View } from './view';

export const PackageAndTask = () => {
    const dispatch = useDispatch();
    const packageList = useSelector((state) => packageListSelector(state));
    const selectedTasks = useSelector((state) => selectedTaskSelector(state));
    const tasks = useSelector((state) => tasksSelector(state));
    const taskForDropdown = useSelector((state) => taskForDropdownSelector(state));
    const isLoading = useSelector((state) => isLoadingSelector(state));
    const packageIsLoading = useSelector((state) => packageIsLoadingSelector(state));
    const taskIsLoading = useSelector((state) => taskIsLoadingSelector(state));
    const isTaskModal = useSelector((state) => isTaskModalSelector(state));

    const [selectedSingleTask, setSelectedSingleTask] = useState(null);
    const [selectedSinglePackage, setSelectedSinglePackage] = useState(null);

    const [searchPKGValue, setSearchPKGValue] = useState('');
    const [searchTSKValue, setSearchTSKValue] = useState('');

    /**
     * Pagination
     */

    useEffect(() => {
        dispatch(packageListAction(1, 10));
    }, []);

    useEffect(() => {
        if (!tasks?.items?.length) dispatch(tasksAction(1, 10));
        dispatch(taskForDropdownAction());
    }, [tasks?.items]);

    const handleModalTaskOpen = (task) => {
        if (!task) return;
        dispatch(isTaskModalAction({ modal: 'ShowTasks' }));
        dispatch(packageIsLoadingAction(true));
        dispatch(selectedTaskAction(task));
    };

    const handleModalClose = () => {
        setSelectedSinglePackage(null);
        setSelectedSingleTask(null);
        dispatch(isTaskModalAction(false));
        dispatch({ type: SELECTED_TASK_COMPLETE, result: [] });
    };

    const openModalAddTask = () => {
        setSelectedSingleTask(null);
        dispatch(isTaskModalAction({ modal: 'AddNewTask' }));
    };

    const handleAddNewPackage = () => {
        dispatch(isTaskModalAction({ modal: 'AddPackage' }));
    };

    const handlePKGPagination = (event, value) => {
        dispatch(packageListAction(value, 10));
    };

    const handleTSKPagination = (event, value) => {
        dispatch(tasksAction(value, 10));
    };

    const handlePKGSearch = (e) => {
        const query = e.target.value;
        setSearchPKGValue(query);

        dispatch(packageIsLoadingAction(true));
        /**
         * when query is empty, fetch all data
         */

        if (!query) {
            dispatch(packageListAction(1, 10));
            return;
        }

        dispatch(searchAction(PACKAGE_SEARCH, query, PACKAGE_LIST_COMPLETE));
    };

    const handleTSKSearch = (e) => {
        const query = e.target.value;
        setSearchTSKValue(query);

        dispatch(taskIsLoadingAction(true));

        /**
         * when query is empty, fetch all data
         */

        if (!query) {
            dispatch(tasksAction(1, 10));
            return;
        }

        dispatch(searchAction(TASK_SEARCH, query, GET_TASKS_COMPLETE));
    };

    return (
        <View
            packageList={packageList}
            handleModalTaskOpen={handleModalTaskOpen}
            handleModalClose={handleModalClose}
            openModalAddTask={openModalAddTask}
            isTaskModal={isTaskModal}
            selectedTasks={selectedTasks}
            isLoading={isLoading}
            packageIsLoading={packageIsLoading}
            taskIsLoading={taskIsLoading}
            tasks={tasks}
            taskForDropdown={taskForDropdown}
            selectedSingleTask={selectedSingleTask}
            setSelectedSingleTask={setSelectedSingleTask}
            selectedSinglePackage={selectedSinglePackage}
            setSelectedSinglePackage={setSelectedSinglePackage}
            handleAddNewPackage={handleAddNewPackage}
            handlePKGPagination={handlePKGPagination}
            handleTSKPagination={handleTSKPagination}
            handlePKGSearch={handlePKGSearch}
            searchPKGValue={searchPKGValue}
            handleTSKSearch={handleTSKSearch}
            searchTSKValue={searchTSKValue}
        />
    );
};
