import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';

// Styling
const useStyles = makeStyles((theme) => ({
    select: {
        padding: '4px ',
        paddingRight: '0 !important',
        backgroundColor: 'transparent !important',
    },
    selectControl: {
        minWidth: ({ minWidth }) => minWidth || '95px',
        borderRadius: 20,
        textAlign: 'center',
        fontWeight: 600,
    },
    green: {
        background: theme.palette.active.secondary,
        color: theme.palette.active.main,
    },
    red: {
        background: theme.palette.rejected.secondary,
        color: theme.palette.rejected.main,
    },
    yellow: {
        background: theme.palette.pending.secondary,
        color: theme.palette.pending.main,
    },
    normal: {
        background: theme.palette.gray,
    },

    arrowDownIcon: {
        display: 'none',
    },
}));

export const SelectDropDown = ({ data, selectedItem, colors, minWidth, handleOnChange }) => {
    const classes = useStyles({ minWidth });

    return (
        <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={selectedItem}
            onChange={handleOnChange}
            className={clsx(classes.selectControl, {
                [classes.green]: selectedItem === colors.green,
                [classes.red]: selectedItem === colors.red,
                [classes.yellow]: selectedItem === colors.yellow,
                [classes.normal]: selectedItem === colors.normal,
            })}
            classes={{
                select: classes.select,
                icon: classes.arrowDownIcon,
            }}
            disableUnderline
        >
            {data?.map((item) => (
                <MenuItem key={item} value={item}>
                    {item}
                </MenuItem>
            ))}
        </Select>
    );
};

SelectDropDown.propTypes = {
    handleOnChange: PropTypes.func.isRequired,
    data: PropTypes.array.isRequired,
    selectedItem: PropTypes.string.isRequired,
    minWidth: PropTypes.string,
    colors: PropTypes.object.isRequired,
};
