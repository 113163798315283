import { all } from 'redux-saga/effects';

import authUser from './loginSaga';
import userList from './userListSaga';
import forgotPassword from './forgotPasswordSaga';
import userInfo from './userSaga';
import localToken from './localSaga';
import customerList from './customerSaga';
import invoiceList from './invoiceSaga';
import packageSaga from './packageSaga';
import taskSaga from './taskSaga';
import dashboardSaga from './dashboardSaga';
import searchSaga from './searchSaga';

export default function* rootSaga() {
    yield all([
        localToken(),
        authUser(),
        userList(),
        forgotPassword(),
        userInfo(),
        customerList(),
        invoiceList(),
        packageSaga(),
        taskSaga(),
        dashboardSaga(),
        searchSaga(),
    ]);
}
