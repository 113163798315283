import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import DateFnsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { Button, DatePicker } from '..';
// Styling

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            margin: theme.spacing(1),
            width: '25ch',
        },
    },
    paper: {
        padding: '11px',
    },
}));

export const SearchBar = ({
    startDate,
    endDate,
    startDateHandler,
    endDateHandler,
    onSearchHandler,
    isLoading,
    btnTitle,
    disableSecondDatePicker,
}) => {
    const classes = useStyles();

    return (
        <Paper classes={{ root: classes.paper }}>
            <form className={classes.root} noValidate autoComplete='off'>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        disableFuture
                        clearable
                        // openTo='date'
                        value={startDate}
                        label='Start date'
                        inputVariant='outlined'
                        onChange={startDateHandler}
                    />

                    <DatePicker
                        disableFuture
                        clearable
                        openTo='date'
                        value={endDate}
                        label='End date'
                        inputVariant='outlined'
                        onChange={endDateHandler}
                        disabled={disableSecondDatePicker}
                    />
                </MuiPickersUtilsProvider>

                <Button
                    title={btnTitle}
                    onClick={onSearchHandler}
                    width='100px'
                    isLoading={isLoading}
                />
            </form>
        </Paper>
    );
};

SearchBar.propTypes = {
    startDate: PropTypes.any,
    endDate: PropTypes.any,
    startDateHandler: PropTypes.func,
    endDateHandler: PropTypes.func,
    onSearchHandler: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    btnTitle: PropTypes.string.isRequired,
    disableSecondDatePicker: PropTypes.bool,
};
