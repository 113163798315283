import { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import MailIcon from '@material-ui/icons/Email';
import CloseIcon from '@material-ui/icons/Close';
import DateRangeIcon from '@material-ui/icons/DateRange';
import clsx from 'clsx';
import dayjs from 'dayjs';
import DateFnsUtils from '@date-io/dayjs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import { TasksModal } from '../TasksModal/TasksModal';
import { dateFormat } from '../../../../utils/date';
import { Select, DatePicker } from '../../../../components/';

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    container: {
        marginTop: '30px',
        maxHeight: '70vh',
    },
    download: {
        cursor: 'pointer',
    },
    tasks: {
        backgroundColor: theme.palette.gray,
        borderRadius: 20,
        padding: '4px 8px',
        cursor: 'pointer',
    },
    status: {
        borderRadius: 20,
        padding: '2px 12px',
        fontWeight: 800,
    },
    red: {
        background: theme.palette.rejected.secondary,
        color: theme.palette.rejected.main,
    },
    green: {
        background: theme.palette.active.secondary,
        color: theme.palette.active.main,
    },
    yellow: {
        background: theme.palette.pending.secondary,
        color: theme.palette.pending.main,
    },
    other: {
        background: theme.palette.gray,
    },
    gray: {
        background: '#FBFBFB',
    },
    heavyGray: {
        background: '#F7F7F7',
    },
    subtable: {
        '& th': {
            color: '#8F8F8F',
        },
    },
    margin: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(5),
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.common.black,
    },
    editDate: {
        cursor: 'pointer',
        position: 'relative',
        left: '5px',
    },
    closeEditOpenDate: {
        fontSize: '43px',
        cursor: 'pointer',
        position: 'relative',
        top: '8px',
        left: '4px',
    },
    closeIconContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    openEditDateContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
    },
    statusCenter: {
        textAlign: 'center',
    },
}));

function Row(props) {
    const {
        _id,
        user,
        customer,
        number,
        status,
        sub_total,
        tax,
        tax_total,
        total,
        vehicles,
        handleModalOpen,
        created_at,
        updated_at,
        handleOnChange,
        updateOpenDateInInvoice,
        handleSendInvoiceOpen,
    } = props;
    const [open, setOpen] = useState(false);
    const classes = useStyles();
    const [openDate, setOpenDate] = useState(created_at);
    const [allowEditingOpenDate, setAllowEditingOpenDate] = useState(false);

    const selectData = ['Open', 'Due', 'Paid', 'Void'];

    const openDateChanger = (date) => {
        updateOpenDateInInvoice(_id, status, date.$d);
        setOpenDate(date);
        setAllowEditingOpenDate(false);
    };

    const editOpenDateHandler = () => {
        setAllowEditingOpenDate(true);
    };

    return (
        <>
            <TableRow className={open && classes.heavyGray}>
                <TableCell component='th' scope='row'>
                    {number || 'NOT_FOUND'}
                </TableCell>

                <TableCell> {customer?.name || 'NOT_FOUND'}</TableCell>
                <TableCell>{user?.first_name + ' ' + user?.last_name || 'NOT_FOUND'}</TableCell>
                <TableCell>
                    {allowEditingOpenDate && (
                        <div className={classes.closeIconContainer}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <DatePicker
                                    value={openDate}
                                    onChange={openDateChanger}
                                    format='MM/DD/YYYY'
                                    width='120px'
                                />
                            </MuiPickersUtilsProvider>
                            <CloseIcon
                                viewBox='0 0 44 44'
                                className={classes.closeEditOpenDate}
                                onClick={setAllowEditingOpenDate.bind(null, false)}
                            />
                        </div>
                    )}

                    {!allowEditingOpenDate && (
                        <div className={classes.openEditDateContainer}>
                            {dayjs(created_at).format('MM/DD/YYYY')}{' '}
                            <DateRangeIcon
                                className={classes.editDate}
                                onClick={editOpenDateHandler}
                            />
                        </div>
                    )}
                </TableCell>
                <TableCell>{(status !== 'Due' && dateFormat(updated_at)) || '─'}</TableCell>
                <TableCell align='right'>{Number(tax || 0)}%</TableCell>
                <TableCell align='right'>${Number(tax_total || 0).toFixed(2)}</TableCell>
                <TableCell align='right'>${Number(sub_total || '').toFixed(2)}</TableCell>
                <TableCell align='right'>${Number(total || '').toFixed(2)}</TableCell>
                <TableCell className={classes.statusCenter}>
                    <Select
                        data={selectData}
                        minWidth='60px'
                        selectedItem={status}
                        handleOnChange={(e) => handleOnChange(e, _id)}
                        colors={{ green: 'Paid', yellow: 'Open', red: 'Due', normal: 'Void' }}
                    />
                </TableCell>
                <TableCell className={classes.download}>
            
                    <IconButton
                        aria-label='expand row'
                        size='small'
                        className={classes.link}
                    >
                        <Link to={`/generate-invoice/${_id}`} target='_blank' className={classes.link}>
                        <PictureAsPdfIcon />
                    </Link>
                    </IconButton>
                    <IconButton
                        aria-label='expand row'
                        size='small'
                        onClick={() =>
                            handleSendInvoiceOpen({
                                _id: _id,
                                number: number,
                                contact_email: customer?.contact_email,
                            })
                        }
                        className={classes.link}
                    >
                        <MailIcon />
                    </IconButton>
                </TableCell>

                <TableCell>
                    <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow className={clsx(classes.root, open && classes.gray)}>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse in={open} timeout='auto' unmountOnExit>
                        <Box>
                            <Table
                                size='medium'
                                aria-label='purchases'
                                classes={{ root: classes.margin }}
                            >
                                <TableHead>
                                    <TableRow className={classes.subtable}>
                                        <TableCell>VIN Number</TableCell>
                                        <TableCell>Make</TableCell>
                                        <TableCell>Model</TableCell>
                                        <TableCell>Year</TableCell>
                                        <TableCell>Color</TableCell>
                                        <TableCell>Tasks</TableCell>
                                        <TableCell>Cost</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {vehicles?.map((info) => (
                                        <TableRow key={info.make}>
                                            <TableCell>{info?.vin || 'NOT FOUND'}</TableCell>
                                            <TableCell>{info?.make || 'NOT FOUND'}</TableCell>
                                            <TableCell>{info?.model || 'NOT FOUND'}</TableCell>
                                            <TableCell>{info?.year || 'NOT FOUND'}</TableCell>
                                            <TableCell>{info?.color || 'NOT FOUND'}</TableCell>
                                            <TableCell
                                                onClick={handleModalOpen.bind(this, info.tasks)}
                                            >
                                                <span className={classes.tasks}>
                                                    {info?.tasks ? 'See All' : 'No Tasks'}
                                                </span>
                                            </TableCell>
                                            <TableCell>
                                                ${Number(info?.total || '').toFixed(2)}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}

Row.propTypes = {};

export const CollapsibleTable = ({
    invoicesList,
    isLoading,
    handleOnChange,
    updateOpenDateInInvoice,
    handleSendInvoiceOpen,
    handleSendInvoiceClose,
}) => {
    const classes = useStyles();
    const [modalTasks, setModalTasks] = useState([]);

    if (isLoading) return false;

    const handleModalOpen = (tasks) => {
        if (!tasks) return;
        setModalTasks(tasks);
    };

    const handleModalClose = () => {
        setModalTasks([]);
    };

    return (
        <>
            <TasksModal modalTasks={modalTasks} handleModalClose={handleModalClose} />

            <TableContainer className={classes.container}>
                <Table stickyHeader aria-label='sticky table'>
                    <TableHead>
                        <TableRow>
                            <TableCell>Inv. No</TableCell>
                            <TableCell>Clients Name</TableCell>
                            <TableCell>Employee</TableCell>
                            <TableCell>Open Date</TableCell>
                            <TableCell>Close Date</TableCell>
                            <TableCell align='right'>Tax</TableCell>
                            <TableCell align='right'>Total Tax</TableCell>
                            <TableCell align='right'>Subtotal</TableCell>
                            <TableCell align='right'>Total</TableCell>
                            <TableCell className={classes.statusCenter}>Status</TableCell>
                            <TableCell>Export</TableCell>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoicesList?.map((invoice) => (
                            <Row
                                key={invoice._id}
                                {...invoice}
                                handleModalOpen={handleModalOpen}
                                handleModalClose={handleModalClose}
                                handleOnChange={handleOnChange}
                                updateOpenDateInInvoice={updateOpenDateInInvoice}
                                handleSendInvoiceClose={handleSendInvoiceClose}
                                handleSendInvoiceOpen={handleSendInvoiceOpen}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

CollapsibleTable.propTypes = {
    invoicesList: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    handleOnChange: PropTypes.func.isRequired,
    updateOpenDateInInvoice: PropTypes.func.isRequired,
};
