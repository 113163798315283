import { useSelector } from 'react-redux';
import { Container, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';

import { Button } from '../Button/Button';

import { isLoadingSelector } from '../../Redux/selector/';

// Styles
const useStyles = makeStyles((theme, props) => {
    return {
        root: {
            backgroundColor: '#F2F2F2',
            display: 'grid',
            placeItems: 'center',
            minHeight: '100vh',
            width: '100%',
        },
        container: {
            maxWidth: 390,
            width: '94%',
            minHeight: ({ formHeight }) => formHeight,
            backgroundColor: '#fff',
            borderRadius: 10,
            boxShadow: '0 5px 10px 0 rgb(0 0 0 / 10%)',
            margin: '15px',
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            marginTop: '10px',
        },
        input: {
            width: '90%',
            margin: '10px auto',
        },
    };
});

export const Form = ({
    formHeader,
    inputFields,
    buttonTitle,
    formBottom,
    formHeight,
    handleSubmit,
    marginBottom,
}) => {
    const classes = useStyles({ formHeight });
    const isLoading = useSelector((state) => isLoadingSelector(state));

    return (
        <div className={classes.root}>
            <Container className={classes.container}>
                {formHeader}

                <form
                    noValidate
                    autoComplete='off'
                    className={classes.form}
                    onSubmit={handleSubmit}
                >
                    {Object.keys(inputFields).map((key) => (
                        <TextField
                            key={key}
                            id={key}
                            type={key === 'passwordConfirm' ? 'password' : key}
                            error={inputFields[key].error}
                            className={classes.input}
                            inputRef={inputFields[key].ref}
                            label={inputFields[key].label}
                            variant='outlined'
                            placeholder={inputFields[key].placeholder}
                            fullWidth
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    ))}

                    <Button title={buttonTitle} isLoading={isLoading} marginBottom={marginBottom} />

                    {formBottom}
                </form>
            </Container>
        </div>
    );
};

Form.propTypes = {
    formHeader: PropTypes.element.isRequired,
    inputFields: PropTypes.object.isRequired,
    buttonTitle: PropTypes.string.isRequired,
    formBottom: PropTypes.element,
    formHeight: PropTypes.number.isRequired,
    marginBottom: PropTypes.string,
};
