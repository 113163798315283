import { get } from 'lodash';

import {
    CUSTOMER_LIST_COMPLETE,
    CUSTOMER_EDIT_COMPLETE,
    CREATE_NEW_CUSTOMER_COMPLETE,
} from '../actionType';

const initialState = {
    customerList: [],
};

export const customerReducer = (state = initialState, action) => {
    switch (action.type) {
        case CUSTOMER_LIST_COMPLETE:
            return {
                ...state,
                customerList: get(action, 'result.data'),
            };

        case CUSTOMER_EDIT_COMPLETE:
            const index = state.customerList.items.findIndex(({ _id }) => _id === action.id);

            const updatedCustomerData = {
                ...state.customerList.items[index],
                ...action.data,
            };

            const copyCustomerList = [].concat(
                state.customerList.items.slice(0, index),
                updatedCustomerData,
                state.customerList.items.slice(index + 1)
            );

            return {
                ...state,
                customerList: {
                    ...state.customerList,
                    items: copyCustomerList,
                },
            };

        case CREATE_NEW_CUSTOMER_COMPLETE:
            return {
                ...state,
                customerList: {
                    ...state.customerList,
                    items: [...state.customerList.items, action.data],
                },
            };
        default:
            return state;
    }
};
