import { Paper, Typography, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { SearchField } from '../../components/SearchField/searchField';
import { CollapsibleTable } from './components/CollapsibleTable/collapseibleTable';
import { Loading, Pagination, Button } from '../../components/';
import { SendInvoice } from '../Invoices/components/SendInvoice/sendInvoice';
// Styling

const useStyles = makeStyles((theme) => ({
    paper: {
        padding: '15px 20px',
    },
    search: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alingItems: 'center',
    },
}));

export const View = ({
    invoicesList,
    handlePagination,
    handleOnChange,
    isLoading,
    searchValue,
    handleSearch,
    updateOpenDateInInvoice,
    handleSendInvoiceOpen,
    handleSendInvoiceClose,
    isModalSendInvoice,
    emailInfoObj,
    isLoadingAction,
    isLoadingModalAction,
    isLoadingModal,
}) => {
    const classes = useStyles();

    return (
        <>
            <Helmet title='Invoices - TRKRBox' />
            <SendInvoice
                isModal={isModalSendInvoice}
                handleClose={handleSendInvoiceClose}
                isLoading={isLoadingModal}
                InfoEmail={emailInfoObj}
                isLoadingAction={isLoadingModalAction}
            />
            <Paper elevation={0} className={classes.paper}>
                <Typography variant='h6' gutterBottom className={classes.customer}>
                    Recent Invoice List
                </Typography>

                <div className={classes.search}>
                    <SearchField
                        value={searchValue}
                        onChange={handleSearch}
                        marginLeft='0px'
                        marginTop='30px'
                        width='250px'
                    />
                    <Button
                        onClick={handleSearch.bind(null, { target: { value: searchValue } })}
                        title='Search'
                        width='120px'
                        marginLeft='20px'
                        marginTop='27px'
                        marginBottom='0'
                    />
                </div>

                <Loading isLoading={isLoading} />
                <CollapsibleTable
                    invoicesList={invoicesList?.items}
                    isLoading={isLoading}
                    handleOnChange={handleOnChange}
                    updateOpenDateInInvoice={updateOpenDateInInvoice}
                    handleSendInvoiceOpen={handleSendInvoiceOpen}
                />

                <Pagination count={invoicesList?.total_pages} onChange={handlePagination} />
            </Paper>
        </>
    );
};

View.prototype = {
    invoicesList: PropTypes.object.isRequired,
    handlePagination: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    handleOnChange: PropTypes.func.isRequired,
    searchValue: PropTypes.string.isRequired,
    handleSearch: PropTypes.func.isRequired,
    updateOpenDateInInvoice: PropTypes.func.isRequired,
};
