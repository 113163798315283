import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Box, makeStyles, Typography, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { Button, Input, Modal } from '../../../../components/';

import { createCustomerAction } from '../../../../Redux/actions/customerAction';

// Styling

const useStyles = makeStyles((theme) => ({
    container: {
        padding: '20px 20px',
    },
    bold: {
        fontWeight: 600,
    },
    header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    icon: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        borderRadius: 20,
        cursor: 'pointer',
        fontSize: 18,
    },
}));

export const CreateUser = ({ isModal, handleClose, isLoading, isLoadingAction }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const nameRef = useRef(null);
    const addressRef = useRef(null);
    const websiteRef = useRef(null);
    const contactRef = useRef(null);
    const contactNumberRef = useRef(null);
    const emailRef = useRef(null);
    const phoneRef = useRef(null);

    const [nameError, setNameError] = useState(false);
    const [addressError, setAddressError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [websiteError, setWebsiteError] = useState(false);
    const [contactError, setContactError] = useState(false);
    const [contactNumberError, setContactNumberError] = useState(false);

    const inputFields = {
        name: {
            elementType: 'input',
            placeholder: 'Name',
            ref: nameRef,
            error: nameError,
        },
        address: {
            elementType: 'input',
            placeholder: 'Address',
            ref: addressRef,
            error: addressError,
        },
        website: {
            elementType: 'input',
            placeholder: 'Website',
            ref: websiteRef,
            error: websiteError,
        },

        phone: {
            elementType: 'phone',
            placeholder: 'Phone Number',
            ref: phoneRef,
            error: phoneError,
        },
        contact: {
            elementType: 'input',
            placeholder: 'Contact',
            ref: contactRef,
            error: contactError,
        },
        contact_number: {
            elementType: 'input',
            placeholder: 'Contact Number',
            ref: contactNumberRef,
            error: contactNumberError,
        },
        email: {
            elementType: 'email',
            placeholder: 'E-mail',
            ref: emailRef,
            error: emailError,
        },
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const name = nameRef?.current?.value || setNameError(true);
        const address = addressRef?.current?.value || setAddressError(true);
        const email = emailRef?.current?.value || setEmailError(true);
        const phone = phoneRef?.current?.value || setPhoneError(true);
        const website = websiteRef?.current?.value || setWebsiteError(true);
        const contact = contactRef?.current?.value || setContactError(true);
        const contactNumber = contactNumberRef?.current?.value || setContactNumberError(true);

        /** if it has value, false the error */
        name && setNameError(false);
        address && setAddressError(false);
        email && setEmailError(false);
        phone && setPhoneError(false);
        website && setWebsiteError(false);
        contact && setContactError(false);
        contactNumber && setContactNumberError(false);

        /** stop executing  */
        if (!name || !address || !email || !phone || !contact || !contactNumber || !website) return;

        dispatch(isLoadingAction(true));

        dispatch(
            createCustomerAction({
                name,
                address,
                contact_email: email,
                contact,
                phone,
                website,
                contact_number: contactNumber,
            })
        );
    };

    return (
        <Modal open={isModal} handleClose={handleClose} borderRadius={10}>
            <Box className={classes.container}>
                <Box className={classes.header}>
                    <Typography className={classes.bold} variant='h6' gutterBottom>
                        Add New Customer
                    </Typography>
                    <CloseIcon className={classes.icon} onClick={handleClose} />
                </Box>

                <form
                    className={classes.form}
                    noValidate
                    autoComplete='off'
                    onSubmit={handleSubmit}
                >
                    <Grid container>
                        {Object.keys(inputFields).map((key) => (
                            <Grid item xs={6} key={key}>
                                <Input fields={inputFields[key]} />
                            </Grid>
                        ))}
                    </Grid>

                    <Button
                        title='Add'
                        width='100%'
                        marginBottom='0'
                        marginTop='30px'
                        isLoading={isLoading}
                    />
                </form>
            </Box>
        </Modal>
    );
};

CreateUser.prototype = {
    isModal: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    isLoadingAction: PropTypes.func.isRequired,
};
