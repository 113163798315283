import { get } from 'lodash';

/** Utility Reducer */
export const snackbarSelector = (state) => get(state, 'utilityReducer.snackBar');
export const isLoadingSelector = (state) => get(state, 'utilityReducer.isLoading');
export const isLoadingModalSelector = (state) => get(state, 'utilityReducer.isLoadingModal');
export const isModalCreateUserSelector = (state) => get(state, 'utilityReducer.isModalCreateUser');
export const isModalEditUserSelector = (state) => get(state, 'utilityReducer.isModalEditUser');
export const isModalSendInvoiceSelector = (state) =>
    get(state, 'utilityReducer.isModalSendInvoice');
/** Login */
export const tokenSelector = (state) => get(state, 'loginReducer.access_token');

/** Forgot password  */
export const isResetTokenValidSelector = (state) =>
    get(state, 'forgotPasswordReducer.isResetTokenValid');
export const isResetTokenLoading = (state) =>
    get(state, 'forgotPasswordReducer.isResetTokenLoading');
export const showModalSelector = (state) => get(state, 'forgotPasswordReducer.showModal');

/** Dashboard */
export const dashboardInfoSelector = (state) => get(state, 'dashboardRedux.dashboardInfo');

/** USER List */
export const userListSelector = (state) => get(state, 'userListReducer.userList');

/** User Info */
export const userInfoSelector = (state) => get(state, 'userReducer');

/** Customer  */
export const customerListSelector = (state) => get(state, 'customerReducer.customerList');

/** Invoice  */
export const invoiceListSelector = (state) => get(state, 'invoiceReducer.invoicesList');
export const invoiceByIdSelector = (state) => get(state, 'invoiceReducer.invoiceById');
export const isLoadingForSheetSelector = (state) => get(state, 'invoiceReducer.isLoadingForSheet');

/** Package  */
export const packageListSelector = (state) => get(state, 'packageReducer.packageList');
export const packageIsLoadingSelector = (state) => get(state, 'packageReducer.isLoading');

/** Tasks */
export const tasksSelector = (state) => get(state, 'taskReducer.tasks');
export const selectedTaskSelector = (state) => get(state, 'taskReducer.selectedTasks');
export const isTaskModalSelector = (state) => get(state, 'taskReducer.isTaskModal');
export const taskForDropdownSelector = (state) => get(state, 'taskReducer.taskForDropDown');
export const taskIsLoadingSelector = (state) => get(state, 'taskReducer.isLoading');
