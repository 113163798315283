import React from 'react';
import { ResponsiveLine } from '@nivo/line';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core';

// Styling

const useStyles = makeStyles((theme) => ({
    paper: {
        height: 500,
    },
}));

const data = [
    {
        id: 'japan',
        color: 'hsl(135, 70%, 50%)',
        data: [
            {
                x: 'Jan',
                y: 151,
            },
            {
                x: 'Feb',
                y: 106,
            },
            {
                x: 'Mar',
                y: 44,
            },
            {
                x: 'Apr',
                y: 264,
            },
            {
                x: 'May',
                y: 275,
            },
            {
                x: 'Jun',
                y: 34,
            },
            {
                x: 'Jul',
                y: 211,
            },
            {
                x: 'Aug',
                y: 288,
            },
            {
                x: 'Sep',
                y: 86,
            },
            {
                x: 'Oct',
                y: 181,
            },
            {
                x: 'Nov',
                y: 181,
            },
            {
                x: 'Dec',
                y: 181,
            },
        ],
    },
    {
        id: 'us',
        color: 'hsl(347, 70%, 50%)',
        data: [
            {
                x: 'Jan',
                y: 101,
            },
            {
                x: 'Feb',
                y: 76,
            },
            {
                x: 'Mar',
                y: 100,
            },
            {
                x: 'Apr',
                y: 264,
            },
            {
                x: 'May',
                y: 200,
            },
            {
                x: 'Jun',
                y: 34,
            },
            {
                x: 'Jul',
                y: 211,
            },
            {
                x: 'Aug',
                y: 108,
            },
            {
                x: 'Sep',
                y: 86,
            },
            {
                x: 'Oct',
                y: 91,
            },
            {
                x: 'Nov',
                y: 51,
            },
            {
                x: 'Dec',
                y: 281,
            },
        ],
    },
];

export const Line = () => {
    const classes = useStyles();

    return (
        <Paper className={classes.paper}>
            <ResponsiveLine
                data={data}
                margin={{
                    top: 50,
                    right: 30,
                    bottom: 50,
                    left: 60,
                }}
                xScale={{
                    type: 'point',
                }}
                yScale={{
                    type: 'linear',
                    stacked: false,
                    min: 'auto',
                    max: 'auto',
                }}
                minY='auto'
                maxY='auto'
                stacked={false}
                curve='cardinal'
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'Months',
                    legendOffset: 36,
                    legendPosition: 'center',
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: 'count',
                    legendOffset: -40,
                    legendPosition: 'center',
                }}
                enableDotLabel={true}
                animate={true}
                useMesh={true}
                enableSlices='x'
            />
        </Paper>
    );
};
