import { put, call, takeLatest } from 'redux-saga/effects';

import { FORGOT_PASS_START, FORGOT_PASS_SAVE, IS_RESET_TOKEN_VALID } from '../actionType/';
import { snackbarAction, isLoadingAction } from '../actions/utilityActions';

import { FORGOT_PASS, SAVE_PASS } from '../../utils/urls';
import { apiCall } from '../../utils/apiCall';
import { showModalAction, isResetTokenLoadingAction } from '../actions/forgotPasswordAction';

function* forgotPassword({ data }) {
    try {
        const result = yield call(apiCall, FORGOT_PASS, data, null, 'POST');

        yield put(
            showModalAction({
                open: true,
                message: result.data.message,
            })
        );
    } catch (error) {
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error.response?.data.message || 'Internal Error',
            })
        );
    }

    yield put(isLoadingAction(false));
}

function* saveNewPassword({ data, method }) {
    try {
        const URL = method === 'GET' ? `${SAVE_PASS}/${data}` : SAVE_PASS;
        const resetPassResult = yield call(apiCall, URL, method !== 'GET' && data, null, method);

        if (method === 'POST')
            yield put({
                type: IS_RESET_TOKEN_VALID,
                isResetTokenValid: {
                    open: 'success',
                    message: resetPassResult.data?.message,
                },
            });

        if (method === 'POST')
            yield put(
                showModalAction({
                    open: true,
                    message: resetPassResult.data?.message,
                })
            );
    } catch (error) {
        const message =
            'Your request to reset your password has been expired or the link has been already used.';

        yield put({
            type: IS_RESET_TOKEN_VALID,
            isResetTokenValid: {
                open: 'error',
                message: error.response?.data?.message || message,
            },
        });
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error.response?.data.message || 'Internal Error',
            })
        );
    }
    yield put(isLoadingAction(false));
    yield put(isResetTokenLoadingAction(false));
}

export default function* watchForgetPassword() {
    yield takeLatest(FORGOT_PASS_START, forgotPassword);
    yield takeLatest(FORGOT_PASS_SAVE, saveNewPassword);
}
