export const URL_API = 'https://trkrboxapi.heydayphone.com/';

export const AUTH = 'auth';
export const USERLIST = 'user';
export const USERS = 'users';
export const USER_SEARCH = 'user_search';
export const FORGOT_PASS = 'forgot_password';
export const SAVE_PASS = 'reset_password';
export const GET_USER_INFO = 'protected';
export const DASHBOARD_URL = 'dashboard';
export const CUSTOMERS = 'customer';
export const CUSTOMERS_SEARCH = 'customer_search';
export const INVOICES = 'invoice';
export const INVOICES_SEARCH = 'invoice_search';
export const INVOICE_PDF = 'invoiceforpdf';
export const INVOICEPDF = 'invoicepdf';
export const INVOICE_EXCEL = 'invoiceforexcel';
export const INVOICE_EMAIL = 'invoice_email';
export const PACKAGE_LIST = 'package';
export const PACKAGE_SEARCH = 'package_search';
export const TASK = 'task';
export const TASK_SEARCH = 'task_search';
