import { Box, makeStyles, Typography, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';

import { Modal } from '../../../../components/';

// Styling

const useStyles = makeStyles((theme) => ({
    container: {
        width: '450px',
        padding: '15px 20px',
    },
    modalHeader: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    bold: {
        fontWeight: '800',
    },
    close: {
        background: theme.palette.primary.main,
        borderRadius: 15,
        color: theme.palette.common.white,
        cursor: 'pointer',
    },
    tasks: {
        marginTop: theme.spacing(2),
    },
    taskItem: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: '10px auto',
    },
    taskDescription: {
        fontSize: '12px',
    },
}));

export const TasksModal = ({ modalTasks, handleModalClose }) => {
    const classes = useStyles();

    return (
        <Modal open={!!modalTasks.length} borderRadius={10} handleClose={handleModalClose}>
            <Box className={classes.container}>
                <Box className={classes.modalHeader}>
                    <Box>
                        <Typography variant='h5' className={classes.bold}>
                            All Taks
                        </Typography>
                        <Typography component='h6'>CHT34012YH</Typography>
                    </Box>
                    <CloseIcon className={classes.close} onClick={handleModalClose} />
                </Box>
                <Box className={classes.tasks}>
                    {modalTasks?.map((tsk) => (
                        <>
                            <Box className={classes.taskItem}>
                                <Box>
                                    <Typography variant='h6'>{tsk.name}</Typography>
                                    <Typography className={classes.taskDescription} variant='h6'>
                                        Task Description
                                    </Typography>
                                </Box>
                                <Typography variant='h6'>{tsk?.cost || '$0.00'}</Typography>
                            </Box>
                            <Divider />
                        </>
                    ))}
                </Box>
            </Box>
        </Modal>
    );
};

TasksModal.propTypes = {
    modalTasks: PropTypes.object,
    handleModalClose: PropTypes.func.isRequired,
};
