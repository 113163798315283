import { put, call, takeLatest, select } from 'redux-saga/effects';
import {
    PACKAGE_LIST_START,
    PACKAGE_LIST_COMPLETE,
    SHOW_TASK_MODAL_COMPLETE,
    CREATE_PACKAGE,
    EDIT_PACKAGE_START,
    EDIT_PACKAGE_COMPLETE,
} from '../actionType';
import { tokenSelector } from '../selector';
import { apiCall } from '../../utils/apiCall';
import { PACKAGE_LIST } from '../../utils/urls';
import { snackbarAction } from '../actions/utilityActions';
import { packageIsLoadingAction } from '../actions/packageAction';

function* packageList({ pageNumber, size }) {
    yield put(packageIsLoadingAction(true));

    try {
        const token = yield select(tokenSelector);
        const result = yield call(
            apiCall,
            `${PACKAGE_LIST}/${pageNumber}/${size}`,
            null,
            token,
            'GET'
        );

        yield put({ type: PACKAGE_LIST_COMPLETE, result });
    } catch (error) {
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error.response?.data.message || 'Internal Error!',
            })
        );
    }
    yield put(packageIsLoadingAction(false));
}

function* createPackage({ data }) {
    try {
        const token = yield select(tokenSelector);
        const result = yield call(apiCall, PACKAGE_LIST, data, token, 'POST');

        yield put(
            snackbarAction({
                open: true,
                type: 'success',
                message: result.data.message,
            })
        );

        yield put({ type: PACKAGE_LIST_START, pageNumber: 1, size: 10 });
        yield put({ type: SHOW_TASK_MODAL_COMPLETE, isTaskModal: false });
    } catch (error) {
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error.response?.data.message || 'Internal Error!',
            })
        );
    }

    yield put(packageIsLoadingAction(false));
}

function* editPackage({ data: { _id, ...data } }) {
    try {
        const token = yield select(tokenSelector);
        const result = yield call(apiCall, `${PACKAGE_LIST}/${_id}`, data, token, 'PUT');

        yield put({ type: EDIT_PACKAGE_COMPLETE, data: { _id, ...data } });
        yield put({ type: SHOW_TASK_MODAL_COMPLETE, isTaskModal: false });

        yield put(
            snackbarAction({
                open: true,
                type: 'success',
                message: result?.data.message,
            })
        );
    } catch (error) {
        yield put(
            snackbarAction({
                open: true,
                type: 'error',
                message: error.response?.data.message || 'Internal Error!',
            })
        );
    }
    yield put(packageIsLoadingAction(false));
}

export default function* watchPackages() {
    yield takeLatest(PACKAGE_LIST_START, packageList);
    yield takeLatest(CREATE_PACKAGE, createPackage);
    yield takeLatest(EDIT_PACKAGE_START, editPackage);
}
